import { useMutation, useQuery } from "@apollo/client";
import ASSETS from "src/asset";
import { GET_USER_THERAPIST_BOOKING } from "src/hooks/data/queries";
import { useUser } from "../auth/hooks/UserProvider";
import { BeatLoader } from "react-spinners";
import { Check, ContentPaste, Edit, ErrorOutline } from "@mui/icons-material";
import moment from "moment";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { useState } from "react";
import Iframe from "react-iframe";
import { FaVideoSlash } from "react-icons/fa6";
import {
  CANCEL_USER_BOOK_THERAPIST,
  UPDATE_USER_BOOK_THERAPIST,
} from "src/hooks/data/mutations";
import { toast } from "react-toastify";
import { getLocalTimeOfUser } from "src/helpers/utils";

export default function UserTherapistBooking() {
  const { user } = useUser();
  const { data, loading, refetch } = useQuery(GET_USER_THERAPIST_BOOKING, {
    variables: {
      page: 1,
      pageSize: 10,
      userId: Number(user?.id),
    },
    fetchPolicy: "network-only",
  });
  const [cancelbookingTherapist] = useMutation(CANCEL_USER_BOOK_THERAPIST);
  const [updatebookingTherapist] = useMutation(UPDATE_USER_BOOK_THERAPIST);
  const [openCancel, setOpenCancel] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [meetingLink, setMeetLink] = useState("");
  const [endTime, setEndTime] = useState<any>("");
  const [openFrame, setOpenFrame] = useState<boolean>(false);
  const [targetBooking, setTargetBooking] = useState(null);
  const [userSubject, setUserSubject] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const handleTerminate = () => {
    setOpenFrame(false);
    setEndTime("");
    setMeetLink("");
    //@ts-ignore
    handleSubtleUpdate({ meetingHeld: true }, Number(targetBooking?.id));
    // handletermination();
    setOpenReview(true);
  };

  const handleCancellingClick = (values) => {
    setIsLoading(true);
    cancelbookingTherapist({
      variables: {
        cancelBookingId: Number(values?.bookingId),
        therapistId: Number(values?.therapistId),
      },
      onCompleted: (data) => {
        toast.success("Report submitted successfully");
        setIsLoading(false);
        setOpenCancel(false);
        refetch();
      },
      onError: (error) => {
        toast.error("Error cancelling booking. Try again later");
        setIsLoading(false);
        console.log({ error });
      },
    });
  };
  const handleUpdateClick = (values, bookingId) => {
    setIsLoading(true);
    updatebookingTherapist({
      variables: {
        updateBookingId: bookingId,
        payload: values,
      },
      onCompleted: (data) => {
        toast.success("Report submitted successfully");
        setIsLoading(false);
        setOpenReview(false);
        setUserMessage("");
        setUserSubject("");
        refetch();
      },
      onError: (error) => {
        toast.error("Error submitting review. Try again later");
        setIsLoading(false);
        console.log({ error });
      },
    });
  };
  const handleSubtleUpdate = (values, bookingId) => {
    updatebookingTherapist({
      variables: {
        updateBookingId: bookingId,
        payload: values,
      },
      onCompleted: (data) => {
        refetch();
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };

  const allDatas = data?.getUserBooking?.data;

  const nextUpcoming =
    allDatas?.length > 0
      ? allDatas
          ?.filter?.(
            (i) =>
              i?.status?.toLowerCase?.() === "pending" &&
              i?.meetingHeld === false
          )
          ?.sort?.(
            (a, b) =>
              //@ts-ignore
              new Date(a?.availability?.startTime) -
              //@ts-ignore
              new Date(b?.availability?.startTime)
          )
      : [];

  const handleStartMeeting = () => {
    // Parse meeting time in UTC and convert it to local time
    const meetingTimeUTC = moment.utc(nextUpcoming?.[0]?.sessionDate); // Meeting time in UTC
    const localMeetingTime = moment(
      getLocalTimeOfUser(meetingTimeUTC, "YYYY-MM-DDTHH:mm:ss")
    ); // Convert to local

    // Get the current time in the user's local time zone
    const currentTime = moment(); // Automatically local
    const adjustedMeetingTime = localMeetingTime.clone().subtract(1, "minutes"); // Adjust meeting time to 1 minute earlier

    // Log times for debugging
    console.log(
      "Local Meeting Time:",
      localMeetingTime.format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(
      "Adjusted Meeting Time (1 minute earlier):",
      adjustedMeetingTime.format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(
      "Current Local Time:",
      currentTime.format("YYYY-MM-DD HH:mm:ss")
    );

    // Compare local times
    if (currentTime.isSameOrAfter(adjustedMeetingTime)) {
      console.log("Meeting can start now.");
      setMeetLink(nextUpcoming?.[0]?.meetingLink);
      handleSubtleUpdate(
        { status: "CONFIRMED" },
        Number(nextUpcoming?.[0]?.id)
      );
      setOpenFrame(true);
      setTargetBooking(nextUpcoming?.[0]);
      setUserMessage("");
      setUserSubject("");
    } else {
      console.log("Meeting cannot start yet.");
      toast?.error("You can't join the meeting now as the time isn't due yet.");
    }
  };
  return (
    <div>
      {openFrame && (
        <div
          style={{ zIndex: 70 }}
          className="fixed top-0 bottom-0  left-0 right-0 bg-black bg-opacity-70 flex justify-center items-center  "
        >
          <div className="relative w-full h-full ">
            <Iframe
              url={meetingLink}
              width="100%"
              height="100%"
              className="w-full md:h-full  "
              // allowFullScreen={true}
              allow="camera; microphone; fullscreen; autoplay; encrypted-media"
            />
            <h1 className="animate-pulse text-sm lg:text-base font-bold text-white absolute bottom-24 left-2 md:bottom-2 w-max ">
              Meeting will end by {endTime}{" "}
            </h1>
            <div
              onClick={() => handleTerminate()}
              className="absolute bottom-24 md:bottom-2 right-2 w-max   shadow shadow-white  flex items-center gap-2 bg-red-500 text-white px-3 lg:px-5 rounded-md  py-2  cursor-pointer "
            >
              <h1 className=" text-sm lg:text-base">End Meeting</h1>
              <FaVideoSlash className="lg:text-xl" />
            </div>
          </div>
        </div>
      )}
      <ResponsiveOverlay
        isOpen={openReview}
        setIsOpen={setOpenReview}
        title={"Please drop a review about your session"}
        size="small"
      >
        <div className="w-full">
          <div className="space-y-5 p-5 sm:p-10 sm:pt-0 pt-0">
            <div className="space-y-3">
              <label htmlFor="subject" className="text-sm block font-medium">
                Subject
              </label>
              <input
                name="subject"
                id="subject"
                type="text"
                className="text-base w-full px-4 focus:border-blue-600 focus:border-2 py-2.5 outline-none border border-gray-300 rounded"
                placeholder="e.g Excellent session"
                autoComplete="off"
                autoCorrect="off"
                value={userSubject}
                onChange={(e) => setUserSubject(e.target.value)}
              />
            </div>
            <div className="space-y-3">
              <label htmlFor="comment" className="text-sm block font-medium">
                Comment
              </label>
              <textarea
                name="comment"
                id="comment"
                className="text-base w-full h-40 px-4 py-2.5  outline-none border border-gray-300 rounded"
                placeholder="Drop your comment here"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="flex p-5 w-full justify-end gap-5 flex-col-reverse sm:flex-row bg-gray-50 items-center">
            {!isLoading && (
              <button
                onClick={() => setOpenReview(false)}
                className="px-6 py-2 rounded-lg w-full sm:w-max text-center text-base font-semibold bg-white border border-gray-400 text-gray-700"
              >
                Cancel
              </button>
            )}
            <button
              disabled={isLoading}
              onClick={() => {
                if (
                  userSubject?.trim?.() === "" &&
                  userMessage?.trim?.() === ""
                ) {
                  return toast.error("Kindly fill all fields");
                }
                const payload = {
                  user_review_subject: userSubject?.trim?.(),
                  user_review_message: userMessage?.trim?.(),
                  meetingHeld: true,
                  status: "COMPLETED",
                };
                //@ts-ignore
                handleUpdateClick(payload, Number(targetBooking?.id));
              }}
              className="px-6 py-2 rounded-lg w-full sm:w-max text-center text-base font-semibold bg-blue-600 text-white"
            >
              {isLoading ? (
                <BeatLoader size={10} color="#fff" />
              ) : (
                "Send Review"
              )}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>
      <ResponsiveOverlay
        isOpen={openCancel}
        setIsOpen={setOpenCancel}
        title={""}
        size="small"
      >
        <div className="w-full">
          <div className="w-full mx-auto gap-5 p-5 bg-white flex flex-col justify-center items-center">
            <div className="w-14 h-14 flex-shrink-0 rounded-full text-red-600 flex justify-center items-center bg-red-100">
              <ErrorOutline color="inherit" fontSize="large" />
            </div>
            <h2 className="text-2xl text-center canela text-[#344054] font-bold">
              Cancel Appointment
            </h2>
            <p className="text-sm text-center">
              You are about to cancel your therapeutic reservation for{" "}
              <span className="font-semibold">{`${moment(
                //@ts-ignore
                nextUpcoming?.[0]?.availability?.startTime
              )?.format("h:mm a")}-${moment(
                //@ts-ignore
                nextUpcoming?.[0]?.availability?.endTime
              )?.format("h:mm a")}`}</span>{" "}
              on{" "}
              <span className="font-semibold">
                {
                  //@ts-ignore
                  moment(nextUpcoming?.[0]?.availability?.startTime)?.format?.(
                    "dddd Do MMMM YYYY"
                  )
                }
              </span>{" "}
              with{" "}
              <span className="font-semibold">
                {nextUpcoming?.[0]?.therapist?.name}
              </span>
              . Please confirm below.
            </p>
          </div>
          <div className="flex p-5 w-full justify-end gap-5 flex-col-reverse sm:flex-row bg-gray-50 items-center">
            {!isLoading && (
              <button
                onClick={() => setOpenCancel(false)}
                className="px-6 py-2 rounded-lg w-full sm:w-max text-center text-base font-semibold bg-white border border-gray-400 text-gray-700"
              >
                Cancel
              </button>
            )}
            <button
              disabled={isLoading}
              onClick={() => {
                const payload = {
                  bookingId: Number(nextUpcoming?.[0]?.id),
                  therapistId: Number(nextUpcoming?.[0]?.therapist?.id),
                };
                handleCancellingClick(payload);
              }}
              className="px-6 py-2 rounded-lg w-full sm:w-max text-center text-base font-semibold bg-blue-600 text-white"
            >
              {isLoading ? <BeatLoader size={10} color="#fff" /> : "Confirm"}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>

      <div className="flex flex-col-reverse lg:flex-row gap-5">
        <div className="flex-grow divide-y divide-gray-300 bg-white rounded-lg ">
          <h2 className="text-sm font-semibold p-4">Recent Appointments</h2>
          <div className="w-full overflow-x-auto">
            {loading ? (
              <div className="flex h-60 justify-center items-center">
                <BeatLoader color="#FE6D68" size={20} />
              </div>
            ) : allDatas?.length > 0 ? (
              <table className="relative rounded w-full divide-y divide-gray-1 overflow-y-auto  bg-white">
                <thead className=" rounded-lg rounded-t px-5">
                  <tr className="rounded-t-lg">
                    <th
                      scope="col"
                      className={`px-2 py-4 pl-5 text-left text-xs font-medium capitalize whitespace-nowrap`}
                    >
                      Therapist
                    </th>
                    <th
                      scope="col"
                      className={`px-2 py-4  text-left text-xs font-medium capitalize whitespace-nowrap`}
                    >
                      Start Time
                    </th>
                    <th
                      scope="col"
                      className={`px-2 py-4   text-left text-xs font-medium capitalize whitespace-nowrap`}
                    >
                      End Time
                    </th>
                    <th
                      scope="col"
                      className={`px-2 py-4 text-left text-xs font-medium capitalize whitespace-nowrap`}
                    >
                      Meeting Status
                    </th>

                    <th
                      scope="col"
                      className={`px-2 py-4  text-left text-xs font-medium capitalize whitespace-nowrap`}
                    >
                      Feedback
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
                  {allDatas
                    ?.sort?.(
                      (a, b) =>
                        //@ts-ignore
                        new Date(b?.availability?.startTime) -
                        //@ts-ignore
                        new Date(a?.availability?.startTime)
                    )
                    ?.map?.((item: any, index: number) => (
                      <tr
                        className="hover:bg-gray-100 cursor-pointer"
                        key={index}
                      >
                        <td className="px-2 pl-5 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-medium capitalize text-[#545F7D]">
                          {item?.therapist?.name}
                        </td>
                        <td className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal text-[#545F7D]">
                          {moment(item?.availability?.startTime)?.format?.(
                            "h:mm a, Do MMM YYYY"
                          )}
                        </td>
                        <td className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal text-[#545F7D]">
                          {moment(item?.availability?.endTime)?.format?.(
                            "h:mm a, Do MMM YYYY"
                          )}
                        </td>
                        <td className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal capitalize text-[#545F7D]">
                          <p
                            className={`px-2 py-0.5 w-max border rounded-md ${
                              item?.status?.toLowerCase?.() === "inactive"
                                ? "bg-[#545f7d3b] text-black border-[#545f7d3b]"
                                : item?.status?.toLowerCase?.() === "pending"
                                ? "bg-yellow-100 text-yellow-600 border-yellow-600"
                                : item?.status?.toLowerCase?.() === "cancelled"
                                ? "bg-red-100 text-red-600 border-red-600"
                                : item?.status?.toLowerCase?.() === "active" ||
                                  item?.status?.toLowerCase?.() === "completed"
                                ? "bg-green-100 text-green-600 border-green-600 "
                                : "bg-gray-100 text-gray-600 border-gray-600"
                            } text-center`}
                          >
                            {item?.status?.toLowerCase?.() === "confirmed"
                              ? "Attended"
                              : item?.status?.toLowerCase?.()}
                          </p>
                        </td>

                        <td className="px-2 py-4 text-left whitespace-nowrap lg:whitespace-normal text-xs font-normal capitalize text-[#545F7D]">
                          {item?.status?.toLowerCase?.() === "cancelled" ? (
                            <p
                              className={`px-2 py-0.5 w-max border border-red-600 rounded-md bg-red-600 text-white text-center`}
                            >
                              Cancelled
                            </p>
                          ) : item?.status?.toLowerCase?.() === "pending" &&
                            item?.meetingHeld === false ? (
                            <p
                              className={`px-2 py-0.5 w-max border border-yellow-600 rounded-md bg-yellow-600 text-white text-center`}
                            >
                              Pending
                            </p>
                          ) : item?.meetingHeld === true &&
                            item?.user_review_message ? (
                            <p
                              className={`px-2 flex items-center gap-1 hover:opacity-70 py-0.5 w-max border border-green-600 rounded-md bg-green-600 text-white text-center`}
                            >
                              <Check fontSize="inherit" color="inherit" />
                              Sent
                            </p>
                          ) : (
                            <p
                              onClick={() => {
                                setTargetBooking(item);
                                setOpenReview(true);
                                setUserMessage("");
                                setUserSubject("");
                              }}
                              className={`px-2 flex items-center gap-1 hover:opacity-70 py-0.5 w-max border border-blue-600 rounded-md bg-blue-600 text-white text-center`}
                            >
                              <Edit fontSize="inherit" color="inherit" />
                              Write
                            </p>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <div className="flex h-60 gap-2 justify-center flex-col items-center">
                <div className="w-20 h-20 text-4xl bg-gray-50 border border-[#FE6D68] text-[#FE6D68] rounded-lg flex justify-center items-center">
                  <ContentPaste fontSize="inherit" color="inherit" />
                </div>
                No booking available
              </div>
            )}
          </div>
        </div>
        <div className="w-full lg:w-4/12 lg:flex-shrink-0 divide-y  bg-white rounded-lg divide-gray-300">
          <h2 className="text-sm font-semibold p-4">Upcoming Appointment</h2>
          {loading ? (
            <div className="flex h-60 justify-center items-center">
              <BeatLoader color="#FE6D68" size={20} />
            </div>
          ) : nextUpcoming?.length > 0 ? (
            <>
              <div className="flex flex-col justify-center gap-2 p-4 items-center">
                <img
                  src={nextUpcoming?.[0]?.therapist?.imageUrl}
                  alt="doc"
                  className="w-40 h-40 rounded-full"
                />
                <h2 className="text-sm font-semibold">
                  {nextUpcoming?.[0]?.therapist?.name}
                </h2>
                <p className="text-xs">
                  {" "}
                  {moment(nextUpcoming?.[0]?.availability?.startTime)?.format?.(
                    "h:mm a, Do MMMM YYYY"
                  )}
                </p>
              </div>
              <div className="flex flex-col-reverse sm:flex-row lg:flex-col-reverse xl:flex-row gap-2 xl:gap-0 items-center justify-between p-4">
                <button
                  onClick={() => {
                    setOpenCancel(true);
                  }}
                  className="px-4 hover:opacity-70 rounded-lg w-full xl:w-max py-2.5 bg-white border border-gray-400 outline-none text-xs font-semibold"
                >
                  Cancel Appointment
                </button>
                <button
                  disabled={false}
                  onClick={() => {
                    handleStartMeeting();
                  }}
                  className="px-6 disabled:bg-gray-300 w-full xl:w-max disabled:text-gray-600 disabled:border-gray-600  hover:opacity-70 border border-blue-600 rounded-lg py-2.5 bg-blue-600 border-none outline-none text-white text-xs font-semibold"
                >
                  Join meeting
                </button>
              </div>
            </>
          ) : (
            <div className="flex h-60 gap-2 justify-center flex-col items-center">
              <div className="w-20 h-20 text-4xl bg-gray-50 border border-[#FE6D68] text-[#FE6D68] rounded-lg flex justify-center items-center">
                <ContentPaste fontSize="inherit" color="inherit" />
              </div>
              No appointment available
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
