import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ASSETS from "src/asset";
import CarouselView from "../Carousel";
import { ArrowBackIos, Cancel, Close, Favorite } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { ACCEPT_MATCH, REJECT_MATCH } from "../auth/data/mutation";
import { toast } from "react-toastify";
import { useUser } from "../auth/hooks/UserProvider";

export default function ViewProfileSmallScreen({
  data,
  status1,
  status2,
  checkEligibility,
  setPopUp,
  setIsFullMatch,
  mid,
  mstatus,
}) {
  const navigate = useNavigate();
  const { user: us } = useUser();

  const [openImage2, setOpenImage2] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [acceptMatchHandler] = useMutation(ACCEPT_MATCH);
  const [rejectMatchHandler] = useMutation(REJECT_MATCH);
  const [showRejectIcon, setShowRejectIcon] = useState(false);
  const [showAcceptIcon, setShowAcceptIcon] = useState(false);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setOpenImage2(true);
  };

  const handleAcceptMatch = () => {
    setShowAcceptIcon(true);
    acceptMatchHandler({
      variables: {
        payload: {
          match_id: Number(mid),
          user_id: Number(us.id),
        },
      },
      onCompleted: (data) => {
        toast.success("Request sent");
        setShowAcceptIcon(false);
        if (mstatus === "true") {
          setIsFullMatch(true);
        } else {
          navigate(-1);
        }
      },
      onError: (error) => {
        console.log(error);
        setShowAcceptIcon(false);
        toast.error("An error occured");
      },
    });
  };

  const handleRejectMatch = () => {
    setShowRejectIcon(true);
    rejectMatchHandler({
      variables: {
        payload: {
          match_id: Number(mid),
          user_id: Number(us?.id),
        },
      },
      onCompleted: (data) => {
        setShowRejectIcon(false);
        navigate(-1);
      },
      onError: (error) => {
        console.log(error);
        setShowRejectIcon(false);
        toast.error("An error occured");
      },
    });
  };

  const handleReject = () => {
    if (checkEligibility?.()) {
      setPopUp?.(true);
      return;
    }

    handleRejectMatch();
  };
  const handleAccept = () => {
    if (checkEligibility?.()) {
      setPopUp?.(true);
      return;
    }

    handleAcceptMatch();
  };
  return (
    <>
      {openImage2 && (
        <div
          style={{ zIndex: 70 }}
          className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-50 "
        >
          <CarouselView
            images={data?.user?.media}
            setOpenImage2={setOpenImage2}
            selectedImageIndex={selectedImageIndex}
          />
        </div>
      )}
      <div className="fixed top-2 left-0 right-0 bottom-0 overflow-y-auto md:hidden ">
        {/* Image */}
        <div
          // onClick={() => {
          //   setSelectedImage(data?.user?.profile_image_url ?? null);
          //   setOpenImage(true);
          // }}
          className="w-full h-96 relative"
        >
          {!data?.user?.profile_image_url ? (
            <img
              src={ASSETS?.Avi}
              alt="profile"
              className="h-full w-full object-cover"
            />
          ) : (
            <img
              src={data?.user?.profile_image_url}
              alt="profile"
              className="h-full w-full object-cover"
            />
          )}
        </div>

        {/* Profile details */}
        <div className="w-full  bg-white rounded-t-[2rem] -mt-10 relative px-6 py-10 space-y-10 mb-20 ">
          {/* name */}
          <div className="space-y-1">
            <h1 className="font-bold text-xl">
              {" "}
              {data?.user?.first_name ?? "N/A"} {data?.user?.last_name ?? "N/A"}
              , {moment().diff(moment("25/11/1992", "DD/MM/YYYY"), "years")}
            </h1>
            <p className="text-sm"> {data?.user?.occupation ?? "N/A"}</p>
          </div>
          {/* about */}
          <div className="space-y-1">
            <h1 className="font-bold text-xl">About</h1>
            <p className="text-sm"> {data?.user?.about_me ?? "N/A"}</p>
          </div>
          {/* Profile Details */}
          <div className="space-y-1">
            <h1 className="font-bold text-xl">Profile Details</h1>
            <div className="space-y-2">
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Religion
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.religion ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Genotype
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.genotype ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Ethnicity
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.ethnicity ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Location
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.location_country ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Place of Birth
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.origin_country ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Highest Academic Qualification
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.qualification ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Relationship Status
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.status ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Hobbies
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.hobbies ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Preferred Gender
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.preferred_gender ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Preferred Age Range
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.preferred_age ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Preferred Age Genotype
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.preferred_genotype ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Preferred Religion
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.preferred_religion ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Preferred Relationship
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.preferred_status ?? "N/A"}
                </p>
              </div>
              <div className="flex justify-between items-center gap-10">
                <h1 className="text-[#707991] text-sm flex-shrink-0">
                  Preferred Countries
                </h1>
                <p className="text-base font-bold">
                  {" "}
                  {data?.user?.preferred_country ?? "N/A"}
                </p>
              </div>
            </div>
          </div>
          {/* gallery */}
          <div className="space-y-1 pb-16">
            <h1 className="font-bold text-xl">Gallery</h1>
            {data?.user?.media?.length > 0 ? (
              <div className="w-full py-5 grid grid-cols-6 gap-5">
                {data?.user?.media?.map?.((photo: any, i: number) => (
                  <img
                    key={i}
                    onClick={() => handleImageClick(i)}
                    className={`h-52 w-full border object-cover cursor-pointer rounded-lg
        ${i % 5 === 0 || i % 5 === 1 ? "col-span-3" : "col-span-2"}`}
                    src={photo?.url}
                    alt="match"
                  />
                ))}
              </div>
            ) : (
              <p className="text-sm"> No Photos</p>
            )}
          </div>
        </div>
      </div>
      {showRejectIcon && (
        <div
          className="absolute inset-0 flex items-center justify-center"
          style={{
            animation: "fadeInOut 1.5s ease",
          }}
        >
          <Cancel
            style={{
              fontSize: "200px",
              color: "#FF77A8",
            }}
          />
        </div>
      )}
      {showAcceptIcon && (
        <div
          className="absolute inset-0 flex items-center justify-center"
          style={{
            animation: "fadeInOut 1.5s ease",
          }}
        >
          <Favorite
            style={{
              fontSize: "200px",
              color: "#FF77A8",
            }}
          />
        </div>
      )}
      {status1 === "true" && status2 === "null" ? null : (
        <div className="md:hidden fixed z-40 w-full bottom-24 left-0">
          <div className="flex  space-x-4 items-center w-3/4 mx-auto justify-between">
            <div
              style={{
                boxShadow:
                  "-4px -4px 12px 0px #ED2563 inset, 4px 4px 12px 0px #FFD7E3 inset,  6px 10px 24px 0px #FF8CB333 ",
              }}
              onClick={() => {
                handleReject();
              }}
              className="w-14 h-14 rounded-full cursor-pointer shadow-inner flex justify-center bg-[#FFD6E4] text-[#FF77A8] items-center text-3xl"
            >
              <img src={ASSETS?.Rej} alt="add" className="w-6 h-6" />
            </div>
            <div
              style={{
                boxShadow:
                  " -4px -4px 12px 0px #ED2563 inset, 4px 4px 12px 0px #FFD7E3 inset, 6px 10px 24px 0px #FF8CB333",
              }}
              onClick={() => {
                handleAccept();
              }}
              className="w-20 h-20 rounded-full cursor-pointer flex justify-center bg-[#081A51] text-white items-center text-4xl"
            >
              <Favorite fontSize="inherit" />
            </div>
            <div
              style={{
                boxShadow:
                  "-4px -4px 12px 0px #ED2563 inset, 4px 4px 12px 0px #FFD7E3 inset,  6px 10px 24px 0px #FF8CB333 ",
              }}
              onClick={() => {
                navigate(-1);
              }}
              className="w-14 h-14 rounded-full cursor-pointer flex justify-center bg-[#FFD6E4] text-[#FF77A8] items-center text-3xl"
            >
              <img src={ASSETS?.Vpro} alt="add" className="w-6 h-6" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
