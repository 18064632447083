import { Favorite, RocketLaunch } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../auth/hooks/UserProvider";
import ASSETS from "src/asset";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_CONVERSATIONS } from "src/hooks/data/queries";
import { SEND_MESSAGE } from "src/hooks/data/mutations";
import { useState } from "react";
import { toast } from "react-toastify";
import Loading from "../Loading";

export default function LinkMessageMatch({
  data,
  isMainPage = false,
  setIsFullMatch = (e) => {},
}) {
  const navigate = useNavigate();
  const { user } = useUser();
  const [sendMessageHandler] = useMutation(SEND_MESSAGE);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { data: CONVO } = useQuery(GET_USER_CONVERSATIONS, {
    variables: {
      userId: Number(user?.id),
    },
    fetchPolicy: "cache-and-network",
  });

  const targetConversation = CONVO?.conversationsByUserId?.filter?.((i) =>
    i?.users
      ?.map?.((b) => b?.id)
      ?.includes?.(isMainPage ? data?.id : data?.user?.id)
  )?.[0];

  const sendMessage = (pay, body) => {
    setIsLoading(true);
    sendMessageHandler({
      variables: {
        message: {
          body: body,
          conversation_id: Number(pay?.id),
          from_user_id: Number(user?.id),
          is_inbound: false,
          message_type: "USER",
          to_user_id: Number(
            pay?.users?.filter((person: any) => person?.id !== user?.id)?.[0]
              ?.id
          ),
        },
      },
      onCompleted: (data) => {
        setIsLoading(false);
        navigate("/dashboard/messages");
      },
      onError: (error) => {
        setIsLoading(false);
        toast.error("Failed to send message. Try again later");
        console.log("sendMessage", { error });
      },
    });
  };

  return (
    <>
      <div className="bg-white space-y-4 pb-20">
        <div
          style={{
            height: "calc(100vh - 400px)",
            minHeight: "350px",
          }}
          className="relative"
        >
          <div className="absolute top-10 right-10 sm:right-1/4 z-10 shadow-sm h-60 rounded-lg rotate-12 border w-40">
            <div className="h-full w-full relative rounded-lg">
              <div className="w-10 h-10 absolute -top-1 -left-2 bg-white border shadow-md z-10 flex justify-center items-center rounded-full text-lg text-[#E94057]">
                <Favorite fontSize="inherit" />
              </div>
              <img
                src={user?.profile_image_url ?? ASSETS?.Avi}
                alt="profile"
                className="h-full w-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg"></div>
            </div>
          </div>
          <div className="absolute bottom-10 left-10 sm:left-1/4 z-20 h-60 rounded-lg -rotate-12 border w-40">
            <div className="h-full w-full relative rounded-lg">
              <div className="w-10 h-10 absolute -bottom-1 -left-2 bg-white border shadow-md z-10 flex justify-center items-center rounded-full text-lg text-[#E94057]">
                <Favorite fontSize="inherit" />
              </div>
              <img
                src={data?.user?.profile_image_url ?? ASSETS?.Avi}
                alt="profile"
                className="h-full w-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg"></div>
            </div>
          </div>
        </div>
        <div className="px-3">
          <h2 className="text-3xl text-center text-[#FE6897] font-bold">
            It’s a match, {user?.first_name}!
          </h2>
          <p className="text-sm text-center">
            Start a conversation now with each other
          </p>
        </div>
        <div className="flex px-3 gap-5 items-center">
          <input
            type="text"
            className="text-lg font-medium px-4 py-2 rounded-lg w-full"
            placeholder="Say something nice"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            onClick={() => {
              if (!targetConversation) {
                return toast.error("Service not available. Try again later");
              }
              if (message?.trim?.() === "") {
                return toast.error("Kindly type in something");
              }
              sendMessage(targetConversation, message?.trim?.());
            }}
            className="p-3 rounded-lg flex justify-center text-base items-center bg-[#FE6897] text-white "
          >
            <RocketLaunch fontSize="inherit" />
          </button>
        </div>
        <div className="grid px-3 grid-cols-5 gap-5">
          <button
            onClick={() => {
              if (!targetConversation) {
                return toast.error("Service not available. Try again later");
              }

              sendMessage(targetConversation, `❤️`);
            }}
            className="p-3 rounded-lg flex love justify-center text-base items-center border w-full border-gray-400"
          >
            ❤️
          </button>
          <button
            onClick={() => {
              if (!targetConversation) {
                return toast.error("Service not available. Try again later");
              }

              sendMessage(targetConversation, `💋`);
            }}
            className="p-3 rounded-lg flex kiss justify-center text-base items-center border w-full border-gray-400 "
          >
            💋
          </button>
          <button
            onClick={() => {
              if (!targetConversation) {
                return toast.error("Service not available. Try again later");
              }

              sendMessage(targetConversation, `💦`);
            }}
            className="p-3 rounded-lg flex water justify-center text-base items-center border w-full border-gray-400"
          >
            💦
          </button>
          <button
            onClick={() => {
              if (!targetConversation) {
                return toast.error("Service not available. Try again later");
              }

              sendMessage(targetConversation, `💯`);
            }}
            className="p-3 rounded-lg flex hundred justify-center text-base items-center border w-full border-gray-400"
          >
            💯
          </button>
          <button
            onClick={() => {
              if (!targetConversation) {
                return toast.error("Service not available. Try again later");
              }

              sendMessage(targetConversation, `👋`);
            }}
            className="p-3 rounded-lg flex wave justify-center text-base items-center border w-full border-gray-400"
          >
            👋
          </button>
        </div>
        {/* <div className="flex justify-center pt-5 items-center">
          <p
            onClick={() => {
              if (isMainPage) {
                return setIsFullMatch?.(false);
              }
              navigate(-1);
            }}
            className="text-center text-lg text-[#E94057] font-semibold"
          >
            Keep Exploring
          </p>
        </div> */}
      </div>
      {isLoading && <Loading />}
    </>
  );
}
