import React from "react";
import Card from "../Card";
import {
  Celebration,
  Chat,
  Favorite,
  Numbers,
  Settings,
} from "@mui/icons-material";
import Happy from "src/assets/happy.jpg";
import NoUser from "src/assets/no_user.png";
import { useNavigate } from "react-router-dom";

export default function LargeScreenPage({
  tabs,
  indexPage,
  displayData,
  handleRejectMatch,
  handleAcceptMatch,
  handleMatchProfileNavigation,
}) {
  const navigate = useNavigate();
  return (
    <div className="md:bg-white hidden md:inline-block">
      <div className="md:p-3 md:border-b border-gray-300">
        <h2 className="text-sm sm:text-xl font-semibold">
          {tabs?.filter?.((i) => i?.name === indexPage)?.[0]?.title}
        </h2>
        <p className="text-[8px] sm:text-sm">
          {tabs?.filter?.((i) => i?.name === indexPage)?.[0]?.description}
        </p>
      </div>
      {displayData?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 md:px-3 py-7">
          {displayData?.map((userMatch: any, i: number) => (
            <Card
              img={userMatch?.user_matched?.profile_image_url}
              name={
                userMatch?.user_matched?.first_name +
                " " +
                userMatch?.user_matched?.last_name
              }
              occupation={userMatch?.user_matched?.occupation}
              userMatch={userMatch}
              request
              key={i}
              btnClickDecline={handleRejectMatch}
              btnClickAccept={handleAcceptMatch}
              type={indexPage}
              handleCardClick={() =>
                handleMatchProfileNavigation(
                  userMatch?.user_matched?.id,
                  userMatch?.user_matched_status,
                  userMatch?.id
                )
              }
            />
          ))}
        </div>
      ) : (
        <div className="space-y-5 flex flex-col justify-between py-5">
          <div className="space-y-5">
            <img
              src={Happy}
              alt="empty"
              className=" md:hidden w-full h-40 rounded-lg object-cover"
            />
            <div className=" mx-auto hidden w-20 h-20 flex-shrink-0 rounded-full bg-gray-200 md:flex justify-center items-center">
              <img
                src={NoUser}
                alt="empty"
                className="w-5 md:w-10 h-5 md:h-10 object-contain"
              />
            </div>
            <h2 className="text-sm sm:text-xl text-center font-semibold text-[#081A51] ">
              You have no{" "}
              {tabs
                ?.filter?.((i) => i?.name === indexPage)?.[0]
                ?.caption?.toLowerCase?.()}{" "}
              data
            </h2>
            <p className="text-xs sm:text-sm w-full pb-5 md:w-3/5 mx-auto text-center">
              View profiles of users interested in meeting you, or browse
              system-generated suggestions. Feel free to adjust your preferences
              for broader reach or chat with your handler for more information.
            </p>
          </div>
          <div className="flex flex-col gap-7 justify-center items-center">
            <button
              onClick={() => {
                navigate("/dashboard/handler-chat");
              }}
              className="px-6 text-sm flex gap-2 items-center md:text-base py-2.5 rounded-lg bg-[#081a51d3] text-white font-semibold hover:opacity-75 disabled:bg-gray-300 disabled:text-gray-700"
            >
              <Chat fontSize="inherit" />
              Chat with Handler
            </button>
          </div>
          <p className="text-xs sm:text-sm w-full pb-5 md:w-3/5 mx-auto text-center">
            Check this space frequently to see profiles of users who have
            requested to meet you, or profiles generated by our system for you
          </p>
        </div>
      )}
    </div>
  );
}
