import { gql } from "@apollo/client";

export const USER_SIGN_UP = gql`
  mutation UserSignUp($payload: CreateUserPayload) {
    userSignUp(payload: $payload) {
      subscription {
        name
        id
        cost
      }
      Invoice {
        id
        payment {
          amount
          created_at
          title
          id
        }
        quantity
        status
        total
        updated_at
        created_at
      }
      first_name
      last_name
      alais
      email
      phone
      about_me
      age
      body_type
      country_code
      created_at
      deleted
      deleted_at
      ethnicity
      last_online
      gender
      genotype
      hobbies
      id
      location_city
      location_country
      location_state
      media_ids
      occupation
      origin_country
      origin_state
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      qualification
      religion
      status
      updated_at

      user_matched {
        id
        deleted
        updated_at
        user_match_status
        user_match {
          id
          first_name
          last_name
          last_online
        }
        created_at
      }
      user_match {
        id
        deleted
        updated_at
        user_match_status
        user_matched {
          id
          first_name
          last_online
          last_name
        }
        created_at
      }
      password
      # user_status
      is_online
      is_available
    }
  }
`;

export const HANDLER_USER_SIGN_UP = gql`
  mutation HandlerUserSignUp($handlerId: Int, $payload: CreateUserPayload) {
    handlerUserSignUp(handler_id: $handlerId, payload: $payload) {
      subscription {
        name
        id
        cost
      }
      Invoice {
        id
        payment {
          amount
          created_at
          title
          id
        }
        quantity
        status
        total
        updated_at
        created_at
      }
      first_name
      last_name
      alais
      email
      phone
      about_me
      age
      body_type
      country_code
      created_at
      deleted
      deleted_at
      ethnicity
      last_online
      gender
      genotype
      hobbies
      id
      location_city
      location_country
      location_state
      media_ids
      occupation
      origin_country
      origin_state
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      qualification
      religion
      status
      updated_at
      user_matched {
        id
        deleted
        updated_at
        user_match_status
        user_match {
          id
          first_name
          last_name
          last_online
        }
        created_at
      }
      user_match {
        id
        deleted
        updated_at
        user_match_status
        user_matched {
          id
          first_name
          last_online
          last_name
        }
        created_at
      }
      password
      # user_status
      is_online
      is_available
    }
  }
`;
export const USER_SIGN_IN = gql`
  mutation UserSignin($email: String!, $password: String!) {
    userSignin(email: $email, password: $password) {
      subscription {
        name
        id
        cost
      }
      created_notifications {
        action_url
        created_at
        deleted
        deleted_at
        description
        destination_id
        id
        is_archived
        is_read
        title
        type
        updated_at
        metadata
      }
      Invoice {
        id
        payment {
          amount
          created_at
          title
          id
        }
        quantity
        status
        total
        updated_at
        created_at
      }
      id
      about_me
      email
      email_is_verified
      first_name
      gender
      genotype
      last_online
      is_available
      last_name
      # interests
      location_city
      location_country
      location_state
      first_timer
      media_ids
      mode
      plan
      paymentDate
      amount
      paystackSubscription {
        active
        created_at
        deleted
        id
        plan
        exp_year
        exp_month
        last4
        card_type
        account_name
        channel
      }
      handler {
        user_name
        id
        profile_image_url
      }
      media {
        id
        url
        title
        description
        is_avatar
        deleted
      }
      occupation
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      profile_image_url
      qualification
      religion
      updated_at
      age
      alais
      body_type
      country_code
      created_at
      deleted
      hobbies
      is_online
      userReport {
        id
        message
        subject
        created_at
      }
      userHandlerRating {
        created_at
        id
        rating
        updated_at
      }
      handler {
        user_name
        id
      }
      handler_conversation {
        id
      }
      status
      # user_status
      phone
      password
      origin_state
      origin_country
      ethnicity
      userStrike {
        id
        reason
        updated_at
        deleted_at
        deleted
        created_at
      }
      handler_rating
    }
  }
`;
export const HANDLER_SIGN_IN = gql`
  mutation HandlerLogin($payload: CreateHandlerPayload) {
    handlerLogin(payload: $payload) {
      id
      user_name
      password
      profile_image_url
      updated_at
      userHandlerRating {
        rating
        id
        created_at
      }
      created_at
      handler_type
    }
  }
`;

export const ACCEPT_MATCH = gql`
  mutation AcceptUserMatch($payload: AcceptUserMatchPayload) {
    acceptUserMatch(payload: $payload)
  }
`;
export const CHECK_VALID_USER = gql`
  mutation Mutation($email: String!) {
    verifyPreEmail(email: $email)
  }
`;
export const VALIDATE_USER = gql`
  mutation ValidatePreUser($payload: UpsertPreUserPayload) {
    validatePreUser(payload: $payload)
  }
`;
export const SEND_MAIL_CODE = gql`
  mutation SendEmailCode($email: String!) {
    sendEmailCode(email: $email)
  }
`;
export const VERIFY_MAIL_CODE = gql`
  mutation VerifyEmail($email: String!, $token: String!) {
    verifyEmail(email: $email, token: $token)
  }
`;

export const REJECT_MATCH = gql`
  mutation RejectUserMatch($payload: RejectUserMatchPayload) {
    rejectUserMatch(payload: $payload)
  }
`;
export const UNMATCH_USER = gql`
  mutation Mutation($email: String!, $payload: UnmatchPayload) {
    verifyPreEmail(email: $email)
    userUnmatch(payload: $payload)
  }
`;
export const TOGGLE_IS_ONLINE = gql`
  mutation UserStatusActioned(
    $userId: Int
    $isOnline: Boolean
    $isAvailable: Boolean
  ) {
    userStatusActioned(
      user_id: $userId
      isOnline: $isOnline
      isAvailable: $isAvailable
    ) {
      user_id
      isAvailable
      isOnline
      lastOnline
    }
  }
`;
export const CREATE_HANDLER_BLIND_DATE = gql`
  mutation CreateBlindDate($payload: CreateBlindDatePayload) {
    createBlindDate(payload: $payload) {
      id
      match_user_id
      matched_user_id
      suggested_by
      match_first_timer
      matched_first_timer
      meeting_held
      match_cancel
      match_upcoming
      match_accept
      match_reject
      matched_cancel
      matched_upcoming
      matched_accept
      matched_reject
      meeting_link
      user_match_theme
      user_matched_theme
      user_match_meeting_time
      user_matched_meeting_time
      deleted
      deleted_at
      updated_at
      created_at
    }
  }
`;
export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserId: ID!, $payload: UpdateUserPayload) {
    updateUser(id: $updateUserId, payload: $payload) {
      subscription {
        name
        id
        cost
      }

      Invoice {
        id
        payment {
          amount
          created_at
          title
          id
        }
        quantity
        status
        total
        updated_at
        created_at
      }
      about_me
      age
      alais
      body_type
      country_code
      created_at
      deleted_at
      deleted
      email
      email_is_verified
      email_token
      first_name
      last_online
      ethnicity
      first_timer
      gender
      genotype
      hobbies
      id
      is_available
      is_online
      location_city
      last_name
      location_country
      location_state
      media_ids
      occupation
      origin_country
      origin_state
      password
      phone
      blind_date_first_timer
      media {
        id
        title
        type
        url
        extension
        deleted_at
        description
        deleted
        created_at
        is_avatar
      }
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      profile_image_url
      qualification
      religion
      status
      updated_at
      # user_status
    }
  }
`;

export const PAYMENT_SUCCESS = gql`
  mutation PaymentSuccess($payload: SuccessPaymentPayload) {
    paymentSuccess(payload: $payload) {
      Invoice {
        created_at
        deleted
        deleted_at
        id
        quantity
        status
        total
        updated_at
        user {
          id
          first_name
        }
      }
      amount
      created_at
      deleted
      deleted_at
      id
      title
      updated_at
    }
  }
`;

export const BLIND_DATE_ACTIONS = gql`
  mutation UpdateBlindDate(
    $updateBlindDateId: Int!
    $payload: UpdateBlindDatePayload
  ) {
    updateBlindDate(id: $updateBlindDateId, payload: $payload) {
      id
      match_user_id
      matched_user_id
      suggested_by
      match_first_timer
      matched_first_timer
      meeting_held
      match_cancel
      match_upcoming
      match_accept
      match_reject
      matched_cancel
      matched_upcoming
      matched_accept
      matched_reject
      meeting_link
      user_match_theme
      user_matched_theme
      user_match_meeting_time
      user_matched_meeting_time
      deleted
      deleted_at
      updated_at
      created_at
    }
  }
`;

export const ACCEPT_BLIND_DATE = gql`
  mutation Mutation($payload: AcceptBlindDateMatchPayload) {
    acceptBlindDateMatch(payload: $payload)
  }
`;

export const CANCEL_BLIND_DATE = gql`
  mutation Mutation($payload: BlindDateUnmatchPayload) {
    blindDateUnmatch(payload: $payload)
  }
`;

export const TERMINATE_BLIND_DATE = gql`
  mutation WherebyDelete($meetingId: String!) {
    wherebyDelete(meetingId: $meetingId)
  }
`;

export const BLIND_DATE_REVIEW = gql`
  mutation BlindDateReview(
    $blindDateReviewId: Int!
    $userId: Int!
    $message: String!
    $time: String!
  ) {
    blindDateReview(
      id: $blindDateReviewId
      user_id: $userId
      message: $message
      time: $time
    )
  }
`;
export const READ_ALL_NOTIFICATIONS = gql`
  mutation ReadBulkUserNotification($userId: Int!) {
    readBulkUserNotification(user_id: $userId) {
      id
    }
  }
`;
