import { useMutation, useQuery } from "@apollo/client";
import { Celebration, Favorite } from "@mui/icons-material";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "src/components/Loading";
import PageLoader from "src/components/PageLoader";
import { ACCEPT_MATCH, REJECT_MATCH } from "src/components/auth/data/mutation";
import { useUser } from "src/components/auth/hooks/UserProvider";
import { GET_USER, GET_USER_CONVERSATIONS } from "src/hooks/data/queries";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import SubscribePopUp from "src/components/SubscribePopUp";
import ViewProfileLargeScreen from "src/components/new_home/ViewProfileLargeScreen";
import ViewProfileSmallScreen from "src/components/new_home/ViewProfileSmallScreen";
import LinkMessageMatch from "src/components/new_home/LinkMessageMatch";
import { useMediaQuery } from "@mui/material";

interface MUSER {
  __typename: String;
  id: String;
  occupation: String;
  last_name: String;
  profile_image_url: String;
  first_name: String;
  last_online: String;
}

const ViewUserProfile = () => {
  const navigate = useNavigate();
  const { user: us } = useUser();
  const parameter = useParams();
  const { data, loading: loading_user } = useQuery(GET_USER, {
    variables: {
      userId: Number(parameter?.matchId),
    },
  });
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [acceptMatchHandler] = useMutation(ACCEPT_MATCH);
  const [rejectMatchHandler] = useMutation(REJECT_MATCH);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status1 = searchParams.get("status1");
  const status2 = searchParams.get("status2");
  const mstatus = searchParams.get("mstatus");
  const mid = searchParams.get("mid");
  const [isMatched, setIsMatched] = useState(false);
  const [userMatched, setUserMatched] = useState<MUSER | null>(null);
  const [cycle, setCycle] = useState("");
  const [isFullMatch, setIsFullMatch] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [popUp, setPopUp] = useState<boolean>(false);

  const { loading, data: chatdata } = useQuery(GET_USER_CONVERSATIONS, {
    variables: {
      userId: Number(us?.id),
    },
    fetchPolicy: "cache-and-network",
  });
  const handleAcceptMatch = () => {
    if (
      us.subscription.name === "basic" &&
      chatdata?.conversationsByUserId >= 2
    ) {
      setPopUp(true);
    } else {
      setActionLoading(true);
      acceptMatchHandler({
        variables: {
          payload: {
            match_id: Number(mid),
            user_id: Number(us?.id),
          },
        },
        onCompleted: (newData) => {
          setActionLoading(false);
          toast.success("Request sent");
          if (mstatus === "true") {
            setCycle("complete");
          } else {
            setCycle("partial");
          }
          setUserMatched(data?.user);
          setIsMatched(true);
        },
        onError: (error) => {
          console.log(error);
          toast.error("An error occured");
          setActionLoading(false);
        },
      });
    }
  };

  const handleRejectMatch = () => {
    if (
      us.subscription.name === "basic" &&
      chatdata?.conversationsByUserId >= 2
    ) {
      setPopUp(true);
    } else {
      setActionLoading(true);
      rejectMatchHandler({
        variables: {
          payload: {
            match_id: Number(mid),
            user_id: Number(us?.id),
          },
        },
        onCompleted: (data) => {
          setActionLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          console.log(error);
          setActionLoading(false);
          toast.error("An error occured");
        },
      });
    }
  };

  const checkEligibility = () => {
    return (
      us.subscription.name === "basic" && chatdata?.conversationsByUserId >= 2
    );
  };
  return (
    <>
      <ResponsiveOverlay
        title={
          cycle === "partial"
            ? "Request sent to " + userMatched?.first_name
            : "Matched with " + userMatched?.first_name
        }
        size="small"
        isOpen={isMatched}
        setIsOpen={() => {
          navigate(-1);
        }}
      >
        {cycle === "complete" && (
          <div className="p-5 space-y-5 pb-10">
            <div className="flex justify-center items-center">
              <div className="w-20 h-20 lg:w-32 lg:h-32 rounded-full bg-orange-100 text-blue-600 flex justify-center items-center text-xl md:text-4xl">
                <Celebration fontSize="inherit" />
              </div>
            </div>
            <div className="">
              <h2 className="text-sm text-center font-bold ">
                Congratulations!!! It's a match
              </h2>
              <p className="text-xs py-2 font-medium text-center">
                You have successfully matched with {userMatched?.first_name}{" "}
                {userMatched?.last_name}
              </p>
              <p className="text-xs text-center">
                You can take this further by dropping a direct message. We are
                rooting for you
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <button
                onClick={() => {
                  navigate("/dashboard/messages");
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-[#081A51] text-white border border-[#081A51] text-xs font-normal "
              >
                Message {userMatched?.first_name}
              </button>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-white text-[#081A51] border border-[#081A51] text-xs font-normal "
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {cycle === "partial" && (
          <div className="p-5 space-y-5 pb-10">
            <div className="flex justify-center items-center">
              <div className="w-20 h-20 lg:w-32 lg:h-32 rounded-full bg-orange-100 flex justify-center items-center text-pink-500 text-xl md:text-4xl">
                <Favorite fontSize="inherit" />
              </div>
            </div>
            <div className="">
              <h2 className="text-sm text-center font-bold ">
                Match request sent!!!
              </h2>
              <p className="text-xs py-2 font-medium text-center">
                You have sent a match request to {userMatched?.first_name}{" "}
                {userMatched?.last_name}
              </p>
              <p className="text-xs text-center"></p>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-[#081A51] text-white border border-[#081A51] text-xs font-normal "
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </ResponsiveOverlay>
      {actionLoading && <Loading />}
      {loading_user ? (
        <PageLoader />
      ) : isSmallScreen && isFullMatch ? (
        <LinkMessageMatch data={data} />
      ) : (
        <>
          <ViewProfileLargeScreen
            data={data}
            status1={status1}
            status2={status2}
            mstatus={mstatus}
            handleAcceptMatch={handleAcceptMatch}
            handleRejectMatch={handleRejectMatch}
          />
          <ViewProfileSmallScreen
            data={data}
            status1={status1}
            status2={status2}
            checkEligibility={checkEligibility}
            setPopUp={setPopUp}
            setIsFullMatch={setIsFullMatch}
            mid={mid}
            mstatus={mstatus}
          />
        </>
      )}
      {popUp && (
        <div
          style={{ zIndex: 70 }}
          className="fixed  top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-50 flex justify-center items-center overflow-y-auto"
        >
          <div className="flex justify-between items-center px-6  py-6">
            <SubscribePopUp setPopUp={setPopUp} />
          </div>
        </div>
      )}
    </>
  );
};

export default ViewUserProfile;
