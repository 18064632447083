import { useMutation } from "@apollo/client";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Notif from "src/assets/notif.svg";
import { useUser } from "src/components/auth/hooks/UserProvider";
import Notifications from "src/components/Notification/notifications";
import SingleButton from "src/components/SingleButton";
import { FaEye } from "react-icons/fa";
import {
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "src/hooks/data/mutations";
import { toast } from "react-toastify";
import Loading from "src/components/Loading";
import { FaBell } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";

const Message = () => {
  const location: any = useLocation();
  const { user, userRefetch } = useUser();
  const msg = location?.state?.msg || null;

  const [showNotifications, setShowNotifications] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const back = () => navigate("/dashboard/notifications");

  const deleteNotificationHandler = () => {
    setLoading(true);
    deleteNotification({
      variables: {
        deleteNotificationId: Number(msg?.id),
      },
      onCompleted: () => {
        setLoading(false);
        toast.success("Notification deleted successfully");
        userRefetch();
        back();
      },
      onError: (error) => {
        setLoading(false);
        toast.error("An error occurred while deleting notification");
      },
    });
  };

  const updateNotif = () => {
    updateNotification({
      variables: {
        payload: {
          id: Number(msg?.id),
          is_read: true,
        },
      },
      onCompleted: () => {
        userRefetch();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const directive = JSON?.parse?.(msg?.metadata?.payload);

  useEffect(() => {
    updateNotif();
  }, []);
  return (
    <div className="">
      {loading && <Loading />}
      <div className="">
        <div className="w-full flex justify-between">
          <p
            onClick={back}
            className=" w-max flex gap-2 cursor-pointer items-center font-semibold"
          >
            <KeyboardBackspaceOutlinedIcon
              style={{
                fontSize: isSmallScreen ? "16px" : "30px",
                cursor: "pointer",
              }}
            />
            <span className=" sm:text-sm text-[10px]">
              Back to notifications
            </span>
          </p>
          <SingleButton
            className="border-[#081A51] border text-[#081A51] gap-1 px-4 py-2 rounded-md flex justify-center items-center cursor-pointer"
            text="Delete"
            icon={<MdDelete />}
            inlineStyle=" text-xs font-medium max-lg:text-[10px]"
            onBtnClick={deleteNotificationHandler}
          />
        </div>
        <div className="bg-white min-h-[400px] w-full mt-8 space-y-5 rounded-md p-4">
          <div className="w-full flex justify-between">
            <div className="flex gap-2 items-center">
              <div className="bg-[#F2F4F7] h-8 w-8 rounded-full flex justify-center items-center lg:w-10 lg:h-10 ">
                <FaBell className="lg:text-xl" />
              </div>
              <h2 className="font-bold capitalize md:text-base text-xs ">
                {msg?.title}
              </h2>
            </div>
            <p>
              <DateRangeOutlinedIcon
                style={{ fontSize: isSmallScreen ? "12px" : "14px" }}
              />
              <span className="font-semibold text-xs ml-2 max-lg:ml-1 max-lg:text-[9px]">
                {moment(msg?.created_at).format("MMM DD, YYYY")}
              </span>
            </p>
          </div>
          <div className="w-full ">
            <p className="text-md max-lg:text-[9px]">
              Dear{" "}
              <span className="font-semibold text-md">{user?.first_name},</span>
            </p>
            <p className="leading-8 max-lg:leading-6 text-xs text-[#11142D] max-lg:text-[9px]">
              {msg?.description}
            </p>
            <div className="flex pt-10 justify-center items-center">
              <button
                onClick={() => {
                  if (msg?.type === "message sent") {
                    return navigate(
                      `/dashboard/${isSmallScreen ? "messenger" : "messages"}`,
                      {
                        state: {
                          directive,
                        },
                      }
                    );
                  }
                  if (msg?.title === "New Blind Date Match") {
                    if (isSmallScreen) {
                      return navigate("/dashboard/meetings", {
                        state: {
                          pageRef: "blindDate",
                        },
                      });
                    }
                    return navigate("/dashboard/blind-date");
                  }
                  // navigate(msg?.action_url);

                  navigate(
                    `/dashboard/request-profile/${directive?.user_matched?.id}?mstatus=${directive?.user_matched_status}&mid=${directive?.id}`
                  );
                }}
                className="px-4 flex items-center gap-1 py-1.5 rounded-lg bg-[#FE6897] text-white text-sm"
              >
                <FaEye />
                View{" "}
                {msg?.type === "NEW_MATCH"
                  ? msg?.title === "New Blind Date Match"
                    ? "blind date"
                    : "match"
                  : msg?.type === "NEW_HANDLER_MATCH"
                  ? "match from handler"
                  : "message"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Notifications
        visible={showNotifications}
        close={() => setShowNotifications(false)}
        user_id={Number(user?.id)}
      />
    </div>
  );
};

export default Message;
