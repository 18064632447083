import axios from "axios";
import { env } from "src/helpers";

function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export const subscribeToNotifications = async (mainUser: any) => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: env("notification_public_key"),
      });

      const payload = {
        endpoint: subscription?.endpoint,
        keys: {
          p256dh: arrayBufferToBase64(subscription?.getKey("p256dh")),
          auth: arrayBufferToBase64(subscription?.getKey("auth")),
        },
        userId: Number?.(mainUser?.id),
      };

      const response = await axios.post(
        `${env("rest_api_endpoint")}/pwa-subscription/pwa-subscription`,
        payload
      );

      if (response?.data?.id) {
        showWelcomeNotification();
      }
    } catch (error) {
      console.error("Error during subscription:", error);
    }
  } else {
    console.error("Service Workers are not supported in this browser.");
  }
};

export const showWelcomeNotification = () => {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification("Welcome to Nirvanae App!", {
        body: "You’ll now receive updates about your activities.",
        icon: "/logo192.png",
      });
    });
  }
};
