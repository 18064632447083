import React from "react";

export default function LargeScreenTab({
  tabs,
  indexPage,
  setIndexPage,
  systemData,
  pendingData,
  matchData,
}) {
  return (
    <div className="hidden md:flex space-x-3 overflow-x-auto sm:space-x-5">
      {tabs?.map((tab, index) => (
        <button
          key={index}
          className={`flex gap-2 items-center select-none border border-[#081a51] whitespace-nowrap py-1 text-[8px] font-medium rounded-lg px-4  sm:text-base ${
            tab?.name === indexPage
              ? "bg-[#081a51] text-white"
              : "bg-transparent text-[#081a51] hover:opacity-75"
          }`}
          onClick={() => {
            setIndexPage(tab?.name);
          }}
        >
          {tab?.caption}
          <span
            className={` rounded-full w-4 h-4 sm:w-5 sm:h-5 ${
              tab?.name === indexPage
                ? "bg-white text-[#081a51]"
                : " bg-[#081a51] text-white"
            } text-[8px] sm:text-xs flex justify-center items-center `}
          >
            {tab?.name === "system"
              ? systemData?.length
              : tab?.name === "pending"
              ? pendingData?.length
              : matchData?.length}
          </span>
        </button>
      ))}
    </div>
  );
}
