import moment from "moment";
import { useEffect, useState } from "react";
import ASSETS from "src/asset";
import { formatDateTime, sortMessages } from "src/helpers/dateTime";
import ScrollToBottom from "react-scroll-to-bottom";
import { BsSendFill } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa6";
import {
  RATE_HANDLER,
  REPORT_ISSUE,
  SEND_HANDLER_MESSAGE,
} from "src/hooks/data/mutations";
import { GET_USER_HANDLER_CONVERSATION } from "src/hooks/data/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useUser } from "src/components/auth/hooks/UserProvider";
// interface Message {
//   body: string;
//   created_at: string;
//   deleted: boolean;
//   id: string;
//   media_url: string | null;
//   message_read: boolean;
//   message_read_at: string | null;
//   message_sender: string;
//   metadata: any | null;
//   status: any | null;
//   subject: string;
//   __typename: string;
// }

// interface Handler {
//   profile_image_url: string;
//   user_name:string;
// }

// interface UserMessage {
//   messageView: Message[];
//   handler: Handler;
//   user_profile_url: string;
// }

const UserMessage = ({ messageView, handler, user_profile_url }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const sortedMessages = sortMessages(messageView);
  const { user, userRefetch } = useUser();
  const [sendHandlerMessage] = useMutation(SEND_HANDLER_MESSAGE);

  const { data: conversation_data, refetch } = useQuery(
    GET_USER_HANDLER_CONVERSATION,
    {
      variables: {
        userId: Number(user?.id),
      },
    }
  );

  const sendmessage = () => {
    setLoading(true);
    sendHandlerMessage({
      variables: {
        message: {
          message_sender: "USER",
          subject: "Not-Available",
          body: message,
          handler_conversation_id: Number(
            conversation_data?.handlerConversationsByUserId?.id
          ),
          //   media_ids,
        },
      },
      onCompleted: (data) => {
        setLoading(false);
        refetch();
        setMessage("");
        // setMessageView([...messageView, message]);
        // setHandlerResponse(true);
        // toggleMessageView(!showMessage);
        // setMessage({ id: 0, header: "", message: "", isHandler: false });
        // setSelectedFileState([{ file: "" }]);
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };
  return (
    <div className="h-full overflow-y-auto flex flex-col">
      <ScrollToBottom className="space-y-6 h-full overflow-y-auto py-4">
        {sortMessages(messageView || [])
          ?.sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
          )
          ?.map((i) => (
            <div key={i?.id}>
              <div
                className={`${
                  i?.message_sender !== "HANDLER"
                    ? "flex flex-col justify-end items-end px-4"
                    : "flex flex-col items-start justify-start px-4"
                }`}
              >
                <h1
                  className={`${
                    i?.message_sender !== "HANDLER"
                      ? "text-xs md:text-sm lg:text-base xl:text-lg font-medium bg-blue-500 bg-opacity-50 max-w-[14rem] sm:max-w-[16rem] md:max-w-[18rem] lg:max-w-md xl:max-w-xl px-2 md:px-3 xl:px-4 xl:py-3 py-2 w-max rounded-md"
                      : "text-xs md:text-sm lg:text-base xl:text-lg font-medium bg-red-500 bg-opacity-20 w-max max-w-[14rem] sm:max-w-[16rem] md:max-w-[18rem] lg:max-w-md xl:max-w-xl px-2 md:px-3 py-2 rounded-md xl:px-4 xl:py-3"
                  }`}
                >
                  {i?.body}
                </h1>
                <p className="text-[11px] pb-4 md:pb-6 xl:pb-8">
                  {moment(i?.created_at).fromNow()}
                </p>
              </div>
            </div>
          ))}
      </ScrollToBottom>

      <div className="w-full border-t flex-shrink-0 border-b flex items-center py-4 gap-4 justify-between px-4 md:px-5 ">
        <textarea
          placeholder="input message here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="placeholder:text-xs py-2 px-3 rounded-md bg-babygrey bg-opacity-50 w-full h-16  outline-none resize-none overflow-y-auto text-xs xl:text-sm"
          name=""
          id=""
          cols={30} // Use numbers here
          rows={10} // Use numbers here
        ></textarea>

        <button
          disabled={loading}
          onClick={() => {
            if (message?.trim?.() === "") return;
            sendmessage();
          }}
          className=" w-8 h-8 md:w-14 md:h-14 py-2 cursor-pointer flex-shrink-0 bg-[#FE6897] rounded-md flex justify-center items-center"
        >
          {loading ? (
            <FaSpinner className="text-white text-2xl md:text-3xl xl:text-4xl animate-spin" />
          ) : (
            <BsSendFill className="text-white text-2xl md:text-3xl xl:text-4xl" />
          )}
        </button>
      </div>
    </div>
  );
};

export default UserMessage;

// {sortedMessages?.map((msg) => (
//         <div
//           className={`w-full my-1.5 bg-white ${
//             msg?.message_sender !== "HANDLER"
//               ? null
//               : "border-l-4 border-[#017EFA]"
//           } shadow-md rounded-lg`}
//           key={msg.id}
//         >
//           {msg?.message_sender !== "HANDLER" ? (
//             <>
//               <div className="w-full flex justify-between  border-b-2 items-center">
//                 <div className="flex p-3 gap-2 items-center">
//                   {
//                     <img
//                       src={user_profile_url ?? ASSETS?.Avi}
//                       className="h-8 w-8 flex-shrink-0 rounded-full"
//                       alt="img"
//                     />
//                   }
//                   <p className="text-sm font-semibold">Me</p>
//                 </div>
//                 <p className=" px-3 pb-2 sm:p-3 lg:text-xs text-[9px]">
//                   {moment(msg?.created_at).format("MMM DD, YYYY, hh:mm a")}
//                 </p>
//               </div>
//               <div className=" w-full p-3">
//                 <p className=" text-xs lg:text-sm text-gray-500 capitalize font-bold">
//                   {msg?.subject ?? ""}
//                 </p>
//                 <p className="text-xs leading-8">{msg?.body ?? ""}</p>
//               </div>
//             </>
//           ) : (
//             <>
//               <div className="w-full flex justify-between flex-col sm:flex-row items-start sm:items-center border-b-2">
//                 <div className="flex p-3 gap-2 justify-center items-center">
//                   {
//                     <img
//                       src={handler?.profile_image_url ?? ASSETS?.Avi}
//                       className="h-[24px] w-[24px text-xs"
//                       alt="img"
//                     />
//                   }
//                   <p className="text-sm font-semibold">{handler?.user_name}</p>
//                   <div className=" ml-3 border border-[#017EFA] bg-blue-100 rounded-full px-4 py-1 flex justify-center items-center text-[#017EFA] text-xs">
//                     <p className="text-[9px]">Handler</p>
//                   </div>
//                 </div>
//                 <p className=" px-3 pb-2 sm:p-3 lg:text-xs text-[9px]">
//                   {moment(msg?.created_at).format("MMM DD, YYYY, hh:mm a")}
//                 </p>
//               </div>
//               <div className=" w-full p-3">
//                 <p className="text-xs leading-8">
//                   {msg?.subject ?? ""}
//                   {msg?.body ?? ""}{" "}
//                 </p>
//               </div>
//             </>
//           )}
//         </div>
//       ))}
