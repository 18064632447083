import { CheckCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ASSETS from "src/asset";
import Cancel from "src/assets/cancel.svg";
import Mark from "src/assets/mark.svg";
import SingleButton from "src/components/SingleButton";
import { useCurrency } from "src/context/AmountProvider";
import {
  GENERICPLANSNG,
  GENERICPLANSUSD,
  plansWordings,
} from "src/helpers/constants";

const MainVerificationSuccess = () => {
  const location = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    waitForAnimate: false,
    cssEase: "linear",
    swipeToSlide: true,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { premiumMonth, premiumBi, premiumYear, premiumQuater, country } =
    useCurrency();
  const [currency, setCurrency] = useState(
    country?.trim?.() === "nigeria" ? "₦" : "$"
  );
  const [amount, setAmount] = useState(
    country?.trim?.() === "nigeria" ? "200000" : "200"
  );

  const [selectedPlanTab, setSelectedPlanTab] = useState("paid");

  const navigate = useNavigate();

  //@ts-ignore
  const payload = location?.state?.payload || null;

  const [singlePlan, setSinglePlan] = useState("annually");

  const handleChoosePlan = (passedplan) => {
    setSinglePlan(passedplan?.plan);
    setAmount(passedplan?.amount);
  };

  const majorPlans =
    country?.trim?.() === "nigeria" ? GENERICPLANSNG : GENERICPLANSUSD;

  useEffect(() => {
    if (!payload) {
      navigate("/pre-verify");
    }
  }, [payload]);

  useEffect(() => {
    if (country?.trim?.() === "nigeria") {
      setCurrency("₦");
      setAmount("200000");
    } else {
      setCurrency("$");
      setAmount("200");
    }
  }, [country]);

  return (
    <div className="bg-white overflow-x-hidden w-full h-screen">
      <div className=" h-full flex-col md:flex-row  overflow-x-hidden flex p-4 gap-5 sm:p-6 lg:p-10 w-full">
        {/* text */}
        <div className="space-y-4 flex flex-col justify-center py-4 w-full md:w-1/2  xl:w-2/5 flex-shrink-0">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={ASSETS?.Logo}
            alt="logo"
            className=" w-24 cursor-pointer h-10 sm:h-20"
          />
          <p className="text-[#081A51] playfair text-2xl font-bold sm:text-3xl   md:text-4xl lg:text-5xl xl:text-6xl">
            {`Congratulations ${payload?.first_name} ${payload?.last_name} 🎉!`}
          </p>
          <p className="text-[#223333] jarkata_sans pb-3 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-medium ">
            You are a fit for our matchmaking service .Your thoroughness in
            providing all the necessary information has been very useful and we
            are thrilled to get you onboard. Here’s to our favorite love
            story,🥂 yours.
            <br />
            <br /> Select a membership plan to complete your registration on our
            platform
          </p>

          <div className="w-full py-2 rounded-lg flex  flex-wrap items-center gap-2 md:gap-3 xl:gap-4 justify-between ">
            {majorPlans
              ?.slice?.()
              ?.reverse?.()
              ?.map?.((plan) => (
                <div
                  onClick={() => handleChoosePlan(plan)}
                  className={`${
                    singlePlan === plan?.plan
                      ? "bg-primary-bg px-4 py-2 sm:py-3  h-max  rounded-md text-white text-center cursor-pointer lg:px-6 flex-grow"
                      : "text-primary-bg  rounded-md  text-center bg-[#E8EDFB] px-4 py-2 sm:py-3 cursor-pointer lg:px-6 h-max flex-grow"
                  }  `}
                >
                  <p className="font-bold capitalize text-xs  md:text-sm  xl:text-base">
                    {plan?.plan}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* card */}
        <div className="flex-grow md:w-1/2 xl:w-3/5">
          <div className=" xl:hidden pb-10">
            <div className="border border-gray-300 rounded-lg divide-y">
              <div className="flex p-3 gap-5 justify-end items-center">
                <button
                  onClick={() => setSelectedPlanTab("paid")}
                  className={`text-[10px] sm:text-xs border border-[#FE6897] ${
                    selectedPlanTab === "paid"
                      ? "bg-[#FE6897] text-white"
                      : "text-[#FE6897]"
                  } px-2.5 py-0.5 rounded-lg flex gap-2 items-center `}
                >
                  Paid plan
                  {selectedPlanTab === "paid" && (
                    <CheckCircle fontSize="inherit" />
                  )}
                </button>
                <button
                  onClick={() => setSelectedPlanTab("free")}
                  className={`text-[10px] sm:text-xs border border-[#FE6897] ${
                    selectedPlanTab === "free"
                      ? "bg-[#FE6897] text-white"
                      : "text-[#FE6897] hover:bg-gray-100"
                  } px-2.5 py-0.5 rounded-lg flex gap-2 items-center `}
                >
                  Free plan
                  {selectedPlanTab === "free" && (
                    <CheckCircle fontSize="inherit" />
                  )}
                </button>
              </div>
              <div className="p-3">
                {selectedPlanTab === "paid" ? (
                  <div className="py-3">
                    <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
                      <p className="text-white text-2xl font-semibold">
                        Premium Tier
                      </p>
                      <p className="mt-4 text-xs text-white">
                        {plansWordings?.[singlePlan]?.caption}
                      </p>
                      <div className="flex items-center mt-8">
                        <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                          {currency} {Number(amount)?.toLocaleString?.()}
                        </p>
                        <p className="text-white text-xs"> / {singlePlan}</p>
                      </div>
                      <SingleButton
                        className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
                        text="Get Started Now"
                        inlineStyle="text-center text-[#081A51]"
                        onBtnClick={() =>
                          navigate("/get-started/premium", {
                            state: {
                              currency_code: PlanAmountType(
                                singlePlan,
                                premiumMonth,
                                premiumBi,
                                premiumYear,
                                premiumQuater,
                                1
                              ),
                              plan_cost: PlanAmountType(
                                singlePlan,
                                premiumMonth,
                                premiumBi,
                                premiumYear,
                                premiumQuater,
                                0
                              ),
                              plan_duration: singlePlan,
                              payload,
                            },
                          })
                        }
                      />
                      <div>
                        {plansWordings?.[singlePlan]?.descriptions?.map?.(
                          (item, index) => (
                            <div key={index} className="flex mt-10">
                              <img src={Mark} className="h-6 mr-4" alt="mark" />
                              <p className="text-white text-sm max-md:text-[11px]">
                                {item}
                              </p>
                            </div>
                          )
                        )}
                        {plansWordings?.[singlePlan]?.constraints &&
                          plansWordings?.[singlePlan]?.constraints?.map?.(
                            (item, index) => (
                              <div key={index} className="flex mt-10">
                                <img
                                  src={Cancel}
                                  className="h-6 mr-4"
                                  alt="mark"
                                />
                                <p className="text-white text-sm max-md:text-[11px]">
                                  {item}
                                </p>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-3">
                    <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
                      <p className="text-[#081A51] text-2xl font-semibold">
                        Free Tier
                      </p>
                      <p className="mt-4 text-[#223333]">
                        Ideal for individuals who need a quick access to basic
                        features with an idea of exploring our platofrm
                      </p>
                      <div className="flex items-center mt-8">
                        <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                          {currency} 0
                        </p>
                        <p className="text-[#223333] text-xs">
                          {" "}
                          / {singlePlan}
                        </p>
                      </div>
                      <SingleButton
                        className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                        text="Get Started Now"
                        inlineStyle="text-center text-[#081A51]"
                        //@ts-ignore
                        onBtnClick={() => {
                          navigate("/get-started/basic", {
                            state: {
                              payload,
                            },
                          });
                        }}
                      />
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          View profiles in our private matchmaking dating pool,
                          get new random suggestions in Discover.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          Receive the Matchmaker’s Monthly Digest, a niche
                          publication sent to your mail.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No access to matches with more precision in Handler’s
                          suggestions.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No personalized matching by handler
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No matching with private subscribers.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          Limited handler support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No access to system scheduled Blind Dates.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No access to personalized Blind dates by Handler.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No after match support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No scheduled therapy sessions.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No access to therapy reports.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-[#223333] text-sm max-md:text-[11px]">
                          No feedback on potential matches.
                        </p>
                      </div>
                      {/* <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    Handler unlocked for all your questions, complaints and for
                    direct referrals
                  </p>
                </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="p-4 lg:grid hidden grid-cols-2 gap-5">
            <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
              <p className="text-white text-2xl font-semibold">Premium Tier</p>
              <p className="mt-4 text-white text-xs">
                {plansWordings?.[singlePlan]?.caption}
              </p>
              <div className="flex items-center mt-8">
                <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                  {currency} {Number(amount)?.toLocaleString?.()}
                </p>
                <p className="text-white text-xs"> / {singlePlan}</p>
              </div>
              <SingleButton
                className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
                text="Get Started Now"
                inlineStyle="text-center text-[#081A51]"
                onBtnClick={() => {
                  navigate("/get-started/premium", {
                    state: {
                      currency_code: PlanAmountType(
                        singlePlan,
                        premiumMonth,
                        premiumBi,
                        premiumYear,
                        premiumQuater,
                        1
                      ),
                      plan_cost: PlanAmountType(
                        singlePlan,
                        premiumMonth,
                        premiumBi,
                        premiumYear,
                        premiumQuater,
                        0
                      ),
                      plan_duration: singlePlan,
                      payload,
                    },
                  });
                }}
              />
              <div>
                {plansWordings?.[singlePlan]?.descriptions?.map?.(
                  (item, index) => (
                    <div key={index} className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="mark" />
                      <p className="text-white text-sm max-md:text-[11px]">
                        {item}
                      </p>
                    </div>
                  )
                )}
                {plansWordings?.[singlePlan]?.constraints &&
                  plansWordings?.[singlePlan]?.constraints?.map?.(
                    (item, index) => (
                      <div key={index} className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          {item}
                        </p>
                      </div>
                    )
                  )}
              </div>
            </div>
            <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
              <p className="text-[#081A51] text-2xl font-semibold">Free Tier</p>
              <p className="mt-4 text-[#223333]">
                Ideal for individuals who need a quick access to basic features
                with an idea of exploring our platofrm
              </p>
              <div className="flex items-center mt-8">
                <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                  {currency} 0
                </p>
                <p className="text-[#223333] text-xs"> / {singlePlan}</p>
              </div>
              <SingleButton
                className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                text="Get Started Now"
                inlineStyle="text-center text-[#081A51]"
                onBtnClick={() =>
                  navigate("/get-started/basic", {
                    state: {
                      payload,
                    },
                  })
                }
              />
              <div className="flex mt-10">
                <img src={Mark} className="h-6 mr-4" alt="mark" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  View profiles in our private matchmaking dating pool, get new
                  random suggestions in Discover.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Mark} className="h-6 mr-4" alt="mark" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  Receive the Matchmaker’s Monthly Digest, a niche publication
                  sent to your mail.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to matches with more precision in Handler’s
                  suggestions.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No personalized matching by handler.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No matching with private subscribers.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  Limited handler support.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to system scheduled Blind Dates.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to personalized Blind dates by Handler.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No after match support.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No scheduled therapy sessions.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to therapy reports.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No feedback on potential matches.
                </p>
              </div>
              {/* <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                Handler unlocked for all your questions, complaints and for
                direct referrals
              </p>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainVerificationSuccess;

const PlanAmountType = (
  plan: string,
  month: string,
  bi_annual: string,
  annual: string,
  quarter: string,
  index: number
) => {
  switch (plan) {
    case "bi-annually":
      return `${bi_annual.split("-")[index]}`;

    case "annually":
      return `${annual.split("-")[index]}`;

    case "monthly":
      return `${month.split("-")[index]}`;

    case "quarterly":
      return `${quarter.split("-")[index]}`;

    default:
      return ``;
  }
};
