import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdCancel, MdDeleteOutline } from "react-icons/md";

// Custom Next Arrow
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-10"
      onClick={onClick}
    >
      <IoIosArrowForward className="text-primary-bg" />
    </div>
  );
}

// Custom Prev Arrow
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-10"
      onClick={onClick}
    >
      <IoIosArrowBack className="text-primary-bg"  />
    </div>
  );
}

const SimpleSlider = ({ images ,setOpenImage2,selectedImageIndex}) => {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex || 0);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (index) => setCurrentIndex(index),
  };

  return (
    <div className="relative w-full p-4  text-white rounded-lg">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg lg:text-2xl  font-bold">
          Image {currentIndex + 1} of {images.length}
        </h1>
        <MdCancel
          className="text-2xl  lg:text-4xl cursor-pointer"
          onClick={() => setOpenImage2(false)}
        />
      </div>

      {/* Slider */}
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="py-4 lg:py-6 ">
            <img
              src={image.url}
              alt={`Slide ${index + 1}`}
              className=' object-center  object-contain w-full h-[75vh] sm:h-[80vh] '
            />
          </div>
        ))}
      </Slider>
      
    </div>
  );
};

export default SimpleSlider;
