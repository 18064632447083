import { useMutation, useQuery } from "@apollo/client";
import { Person, StarRate } from "@mui/icons-material";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Write from "src/assets/write.svg";
import InputField from "src/components/Input";
import AdminModal from "src/components/Message/AdminModal";
import UserMessage from "src/components/Message/UserMessage";
import Star from "src/components/Rating";
import ReportIsuueComponent from "src/components/ReportIsuueComponent";
import SingleButton from "src/components/SingleButton";
import { useUser } from "src/components/auth/hooks/UserProvider";
import { SideBarContext } from "src/context/SidebarProvider";
// import { SideBarItemObj } from "src/data/sidebar_item";
import {
  RATE_HANDLER,
  REPORT_ISSUE,
  SEND_HANDLER_MESSAGE,
} from "src/hooks/data/mutations";
import { GET_USER_HANDLER_CONVERSATION } from "src/hooks/data/queries";
import Noaccess from "./NoAccessPage";
import ASSETS from "src/asset";

const ChatHandler = ({ bottomRefHeight }) => {
  const { user, userRefetch } = useUser();
  const [sendHandlerMessage] = useMutation(SEND_HANDLER_MESSAGE);
  const [
    rateHandler,
    // { data: rateData, loading: rateloading, error: raterror },
  ] = useMutation(RATE_HANDLER);
  const [
    reportIssue,
    // { data: issuedata, loading: reportloading, error: reporterror },
  ] = useMutation(REPORT_ISSUE);
  const { data: conversation_data, refetch } = useQuery(
    GET_USER_HANDLER_CONVERSATION,
    {
      variables: {
        userId: Number(user?.id),
      },
    }
  );

  const [message, setMessage] = useState({
    id: 1,
    header: "",
    message: "",
    isHandler: false,
  });

  const [report, setReport] = useState({
    subject: "",
    message: "",
  });

  const [handlerResponse, setHandlerResponse] = useState(false);
  // const [selectedFileState, setSelectedFileState] = useState<any>([
  //   { file: "" },
  // ]);
  const [showModal, setShowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const { showMessage, toggleMessageView } = useContext<any>(SideBarContext);
  // const [showNotifications, setShowNotifications] = useState(false);
  const starRatingOptions = [1, 2, 3, 4, 5];
  const [messageDropDown, setMessageDropDown] = useState(
    conversation_data?.handlerConversationsByUserId?.handler_messages?.length >
      0
      ? false
      : true
  );
  const [rating, setRating] = useState(
    user?.userHandlerRating?.[0]?.rating ?? 0
  );
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  //   const user = singleUser?.name;

  const disabled = Object.values(message).some((text) => text === "");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMessage((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSentMessage = () => {
    sendHandlerMessage({
      variables: {
        message: {
          message_sender: "USER",
          subject: message.header,
          body: message.message,
          handler_conversation_id: Number(
            conversation_data?.handlerConversationsByUserId?.id
          ),
          //   media_ids,
        },
      },
      onCompleted: (data) => {
        refetch();
        setMessage({
          id: 1,
          header: "",
          message: "",
          isHandler: false,
        });
        setMessageDropDown(!messageDropDown);
        // setMessageView([...messageView, message]);
        // setHandlerResponse(true);
        // toggleMessageView(!showMessage);
        // setMessage({ id: 0, header: "", message: "", isHandler: false });
        // setSelectedFileState([{ file: "" }]);
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };

  const OpenMessage = () => {
    toggleMessageView(showMessage);
    setHandlerResponse(!handlerResponse);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowReportModal(false);
  };

  const handleStarClick = (index) => {
    rateHandler({
      variables: {
        userId: Number(user?.id),
        rating: index,
        handlerId: Number(user?.handler?.id),
      },
      onCompleted: (data) => {
        refetch();
        userRefetch();
        closeModal();
        toast.success("Rating submitted successfully");
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };

  const reportIssueHandler = () => {
    reportIssue({
      variables: {
        message: report.message,
        userId: Number(user?.id),
        subject: report.subject,
      },
      onCompleted: (data) => {
        closeModal();
        setReport({
          subject: "",
          message: "",
        });
        toast.success("Report submitted successfully");
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.message ?? "Error submitting report");
      },
    });
  };

  return (
    // <div className=" bg-white h-full overflow-hidden ">
    //   <div className=" h-full flex flex-col overflow-hidden">

    //   </div>
    <>
      <AdminModal
        visible={showModal}
        close={closeModal}
        headerText="Rate your handler’s Service"
        containerStyle="lg:w-[60%] h-[400px] max-lg:h-[350px] mt-20 bg-white relative w-[95%] rounded-md flex flex-col"
      >
        <div className="py-2 sm:pt-6 space-y-5 w-full flex flex-col items-center">
          <p className="w-full sm:w-[80%] px-2 leading-7 text-center text-sm max-lg:text-xs max-lg:leading-7 ">
            We hope you've had a great experience with your handler! Your
            feedback matters immensely to us, and we'd love to hear your
            thoughts. Please take a moment to rate your recent experience with
            your handler. Your valuable insights will help us improve and
            provide even better service in the future.
          </p>
          <div className="h-14 w-full gap-1 flex items-center justify-center ">
            <p className="font-semibold text-sm text-slate-800 max-lg:text-xs">
              Very bad
              <span className="px-2 max-lg:text-xs">
                {starRatingOptions.map((index) => (
                  <Star
                    key={index}
                    filled={index < rating}
                    onClick={() => handleStarClick(index)}
                  />
                ))}
              </span>{" "}
              Excellent
            </p>
          </div>
        </div>
      </AdminModal>

      <AdminModal
        visible={showReportModal}
        close={closeModal}
        headerText={"Report an Issue"}
        containerStyle=" relative  rounded-md flex flex-col  justify-center item-center px-6 md:px-10 max-w-2xl lg:max-w-3xl"
      >
        <ReportIsuueComponent reportIssueHandler={reportIssueHandler} />
      </AdminModal>

      <div
        style={{ height: `calc(100vh - ${+bottomRefHeight}px)` }}
        className={`absolute top-0  left-0 w-full`}
      >
        <div
          className={`w-full overflow-hidden bg-white transition-all ease-in duration-300 p-0  md:pb-20 lg:border-l flex flex-col relative justify-between border-gray-400 h-full`}
        >
          <div className=" w-full mt-20 md:mt-0 px-1 flex-shrink-0 shadow-md bg-white flex justify-center  items-center divide-x divide-gray-300 gap-3 lg:gap-10 py-4">
            <div className="flex gap-4 justify-center items-center">
              <div className="w-12 h-12 p-1 lg:w-28 lg:h-28 bg-[#081a51d3] rounded-full shadow-md border border-gray-300 text-white flex justify-center items-center">
                {
                  <img
                    src={user?.handler?.profile_image_url ?? ASSETS?.Avi}
                    alt="img"
                    className="w-full h-full rounded-full object-cover text-xs"
                  />
                }
              </div>
              <div className=" flex flex-col justify-center ">
                <p className="text-[#081A51] capitalize font-bold lg:text-xl text-xs">
                  {user?.handler?.user_name}
                </p>
                <p className="text-[#081A51] lg:text-sm text-[9px]">handler</p>
              </div>
            </div>
            <div className="flex gap-3 lg:gap-10 pl-3 items-center">
              {user?.userHandlerRating?.[0]?.rating ? (
                <div className="">
                  <div className="flex gap-1 items-center">
                    {starRatingOptions?.map?.((star, index) => (
                      <span
                        key={index}
                        className={`text-xs sm:text-xl ${
                          star > user?.userHandlerRating?.[0]?.rating
                            ? "text-[#9ca3af]"
                            : "text-[#facc15]"
                        }`}
                      >
                        <StarRate fontSize="inherit" color="inherit" />
                      </span>
                    ))}
                  </div>
                  <p className="text-[8px] sm:text-xs text-center ">
                    You rated ({user?.userHandlerRating?.[0]?.rating}/5)
                  </p>
                </div>
              ) : (
                <SingleButton
                  icon={
                    <StarBorderOutlinedIcon
                      style={{
                        color: rating === 0 ? "black" : "yellow",
                        fontSize: isSmallScreen ? "14px" : "20px",
                      }}
                    />
                  }
                  text="Rate Handler"
                  className="lg:p-4 p-1 flex justify-center items-center border border-[#081A51] rounded-lg cursor-pointer lg:mr-4 mr-1"
                  inlineStyle="text-blue-900 lg:text-xs text-[9px]"
                  onBtnClick={() => {
                    if (rating === 0) {
                      setShowModal(true);
                    }
                  }}
                  disabled={rating !== 0}
                />
              )}
              <SingleButton
                icon={
                  <DriveFileRenameOutlineOutlinedIcon
                    style={{
                      color: "white",
                      marginRight: "3px",
                      fontSize: isSmallScreen ? "14px" : "20px",
                    }}
                  />
                }
                text="Report Issue"
                className="lg:p-4 p-2.5 flex justify-center items-center border bg-red-500 rounded-lg cursor-pointer "
                inlineStyle="text-white lg:text-xs text-[9px]"
                onBtnClick={() => {
                  setMessageDropDown(false);
                  setShowReportModal(true);
                }}
              />
            </div>
          </div>

          <UserMessage
            messageView={
              conversation_data?.handlerConversationsByUserId?.handler_messages
            }
            handler={user?.handler}
            user_profile_url={user?.profile_image_url}
          />
          </div>
         
        </div>
    </>
  );
};
export default ChatHandler;
