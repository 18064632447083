import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ASSETS from "src/asset";
import ViewImageOverlay from "src/overlays/ViewImagOverlay";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Image } from "@mui/icons-material";
import Tippy from "@tippyjs/react";
import SingleButton from "../SingleButton";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import EmptyCard from "../Card/emptyCard";
import moment from "moment";
import NoChat from "src/assets/no_chat.png";

export default function ViewProfileLargeScreen({
  data,
  status1,
  status2,
  mstatus,
  handleAcceptMatch,
  handleRejectMatch,
}) {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImage, setOpenImage] = useState(false);

  return (
    <div className="space-y-10 hidden w-full md:inline-block ">
      <ViewImageOverlay
        setIsOpen={setOpenImage}
        size="small"
        isOpen={openImage}
        title={""}
      >
        <div className=" bg-white flex justify-center items-center space-y-3 w-full">
          <div className="bg-gray-200 min-h-[500px] w-full flex justify-center items-center">
            <img
              src={selectedImage ?? ASSETS?.Avi}
              alt="profile"
              style={{
                height: selectedImage ? "500px" : "300px",
                width: "100%",
                backgroundColor: "#eee",
              }}
              className={" object-center md:object-contain"}
            />
          </div>
        </div>
      </ViewImageOverlay>
      <div
        onClick={() => navigate(-1)}
        className="flex items-center w-max gap-2 cursor-pointer"
      >
        <KeyboardBackspaceOutlinedIcon
          style={{
            cursor: "pointer",
            fontSize: "25px",
          }}
        />
        <span className="text-sm font-bold">Back to requests</span>
      </div>

      <div className="md:pb-16">
        <div
          className="w-full h-40 sm:h-60 lg:h-[297px] bg-[#0000009e] bg-blend-soft-light rounded-lg px-4 py-2 flex justify-between bg-cover bg-center bg-no-repeat relative"
          style={{
            backgroundImage: `url(${ASSETS?.Marvin})`,
          }}
        >
          {
            <div
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              className="absolute text-6xl text-gray-500 lg:text-9xl z-50 w-max "
            >
              <Image fontSize="inherit" color="inherit" />
            </div>
          }
          <div className=" absolute bottom-0 translate-y-5 gap-2 md:gap-5 lg:translate-y-20  flex ">
            <div
              onClick={() => {
                setSelectedImage(data?.user?.profile_image_url ?? null);
                setOpenImage(true);
              }}
              className="lg:w-44 border p-1 text-lg lg:text-7xl flex justify-center items-center border-gray-300 bg-white h-12 w-12 sm:w-20 sm:h-20 lg:h-44 max-lg:w-16 max-lg:h-16 rounded-full bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${data?.user?.profile_image_url})`,
              }}
            >
              <img
                src={data?.user?.profile_image_url ?? ASSETS?.Avi}
                alt="profile"
                className="h-full w-full object-cover rounded-full"
              />
            </div>
            <span className="text-white sm:mt-5 lg:mt-10  max-lg:text-slate-700 md:text-lg font-bold  capitalize whitespace-nowrap text-xs  ">
              {`${data?.user?.first_name} ${data?.user?.last_name}`}
            </span>
          </div>
          <div
            className="grid grid-cols-1 lg:grid-cols-2 bottom-2 lg:gap-5 gap-2 p-2
        absolute right-2"
          >
            {status1 === "true" && status2 === "null" ? (
              <Tippy
                content={
                  "This request is awaiting acceptance from " +
                  `${data?.user?.first_name} ${data?.user?.last_name}`
                }
              >
                <div className="px-8 py-1.5 w-max text-xs text-center font-medium rounded-full bg-yellow-100 text-yellow-600">
                  Pending
                </div>
              </Tippy>
            ) : (
              <>
                <div
                  onClick={() => {
                    handleAcceptMatch();
                  }}
                  className="p-2 flex justify-center items-center bg-white rounded-lg cursor-pointer"
                >
                  <div className="p-1 bg-rose-500 rounded-full flex justify-center items-center mr-2">
                    <FavoriteOutlinedIcon
                      style={{
                        color: "white",
                        height: "8px",
                        fontSize: "10px",
                      }}
                    />
                  </div>
                  <span className="text-slate-700 text-sm max-lg:text-[9px] max-lg:text-xs">
                    {mstatus === "true" ? "Accept" : "Send Request"}
                  </span>
                </div>

                <SingleButton
                  icon={
                    <ClearOutlinedIcon
                      style={{
                        color: "white",
                        marginRight: "6px",
                        fontSize: "12px",
                      }}
                    />
                  }
                  onBtnClick={() => {
                    handleRejectMatch();
                  }}
                  text={"Decline"}
                  className="p-2 flex justify-center items-center bg-rose-600 rounded-lg cursor-pointer "
                  inlineStyle="text-slate-100 text-sm max-lg:text-[9px] max-lg:text-xs"
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className=" bg-white divide-y divide-gray-300 shadow-md rounded-lg">
        <p className="font-bold capitalize p-4 text-base">
          About {`${data?.user?.first_name} ${data?.user?.last_name}`}
        </p>
        {/* about */}
        <div className=" p-5 w-full">
          {data?.user?.about_me ? (
            <p className="text-base font-medium ">{data?.user?.about_me}</p>
          ) : (
            <p className="text-base italic font-medium ">
              No bio/about available
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-2 xl:gap-5 ">
        <EmptyCard header="Personal Information">
          <div className="p-4 space-y-2 pb-0">
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Full name:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.first_name ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Username:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.alais ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Age:{" "}
              <span className="text-sm font-normal capitalize">
                {moment().diff(
                  //@ts-ignore
                  moment(data?.user?.age, "DD/MM/YYYY"),
                  "years"
                ) ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Gender:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.gender ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Genotype:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.genotype ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Etnicity:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.ethnicity ?? "N/A"}
              </span>
            </p>
          </div>
        </EmptyCard>
        <EmptyCard header="Other Details">
          <div className="p-4 space-y-2 pb-0">
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Religion:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.religion ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Occupation:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.occupation ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Location:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.location_country ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Place of birth:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.origin_country ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Highest Academic Qualification:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.qualification ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Relationship Status:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.status ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center max-w-xs xl:max-w-sm">
              Hobbies:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.hobbies ?? "N/A"}
              </span>
            </p>
          </div>
        </EmptyCard>
        <EmptyCard header="Preferences">
          <div className="p-4 space-y-2 pb-0">
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Gender:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.preferred_gender ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred age range:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.preferred_age ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Gender:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.preferred_gender ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Genotype:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.preferred_genotype ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Religion:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.preferred_religion ?? "N/A"}
              </span>
            </p>
            <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
              Preferred Relationship:{" "}
              <span className="text-sm font-normal capitalize">
                {data?.user?.preferred_status ?? "N/A"}
              </span>
            </p>
          </div>
        </EmptyCard>
      </div>
      <div className="w-full p-4 rounded-lg shadow-md bg-white">
        <p className="font-bold capitalize text-base">
          {`${data?.user?.first_name} ${data?.user?.last_name}'s`} Photos
        </p>
        {data?.user?.media?.length > 0 ? (
          <div className="w-full py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {data?.user?.media?.map?.((photo: any, i: number) => (
              <img
                key={i}
                onClick={() => {
                  setSelectedImage(photo?.url);
                  setOpenImage(true);
                }}
                className={`h-40 border border-gray-600 object-cover rounded-lg w-full`}
                src={photo?.url}
                alt="match"
              />
            ))}
          </div>
        ) : (
          <div className=" items-center justify-center w-full h-60 bg-white flex">
            <div className=" flex gap-5 flex-col justify-center items-center">
              <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                <img
                  src={NoChat}
                  alt="empty"
                  className="w-5 md:w-10 h-5 md:h-10 object-contain"
                />
              </div>
              <h2 className="text-lg text-center font-bold text-[#081A51] ">
                No Photos available
              </h2>
              <p className="text-sm w-4/5 mx-auto text-center">
                This user doesn't have any photo in their media
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
