// register env variables here with 1:1 .env
// this will allow to fetch env variables from other places
const ENVIRONMENT_VARIABLES = {
  aws_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  graphql_api_endpoint: process.env.REACT_APP_GRAPHQL_API_ENDPOINT,
  graphql_api_endpoint_ws: process.env.REACT_APP_GRAPHQL_API_WS_ENDPOINT,
  rest_api_endpoint: process.env.REACT_APP_REST_API_ENDPOINT,
  env: process.env.REACT_ENV,
  media_buket_name: process.env.REACT_APP_AWS_MEDIA_BUCKET_NAME,
  paystack_key: process.env.REACT_APP_PAYSTACK_KEY,
  flutterwave_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
  geo_api_key: process.env.REACT_APP_API_KEY,
  notification_public_key: process.env.REACT_APP_NOTIFICATION_PUBLIC_KEY,
};

export const env = (key: keyof typeof ENVIRONMENT_VARIABLES) => {
  return ENVIRONMENT_VARIABLES[key];
};

export const isRunningLocally = () => {
  return (
    ["development", "dev", "local"].includes(env("env") as string) ||
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  );
};
