import React, { useContext, useEffect, useRef, useState } from "react";
import UserTherapy from "./UserTherapy";
import UserBlindDate from "./UserBlindDate";
import UserMessages from "./UserMessages";
import ChatHandler from "./ChatHandler";
import { SideBarContext } from "src/context/SidebarProvider";
import { useLocation } from "react-router-dom";

function UserMessenger({ bottomRefHeight }) {
  const location = useLocation();

  // @ts-ignore
  const pageReference = location?.state?.pageRef || "";

  const [isViewing, setIsViewing] = useState(
    pageReference ? "handlerChat" : "messages"
  );
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(0);
  const { setIsNav } = useContext(SideBarContext);
  const messageRef = useRef<HTMLHeadingElement>(null);
  const handlerChatRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const updateSlider = () => {
      const activeRef =
        isViewing === "messages" ? messageRef.current : handlerChatRef.current;
      if (activeRef) {
        setSliderWidth(activeRef.offsetWidth);
        setSliderPosition(activeRef.offsetLeft);
      }
    };
    updateSlider();
  }, [isViewing]);

  // useEffect(() => {
  //   if (isViewing === "handlerChat") {
  //     setIsNav(false);
  //   } else {
  //     setIsNav(true);
  //   }
  //   return () => {
  //     setIsNav(true);
  //   };
  // }, [isViewing, setIsNav]);
  return (
    <main
      className={` h-full overflow-hidden ${
        isViewing === "messages" ? " space-y-20  " : "space-y-5"
      }`}
    >
      {/* User Select */}
      <div className="relative z-10  ">
        {/* Text Options */}
        <div className="flex items-center  text-base pb-1 md:text-base xl:text-lg w-full ">
          <h1
            ref={messageRef}
            onClick={() => setIsViewing("messages")}
            className={`cursor-pointer w-1/2 text-center ${
              isViewing === "messages"
                ? "font-bold text-[#FE6897]"
                : "font-medium"
            }`}
          >
            Messages
          </h1>
          <h1
            ref={handlerChatRef}
            onClick={() => setIsViewing("handlerChat")}
            className={`cursor-pointer  w-1/2 text-center  ${
              isViewing === "handlerChat"
                ? "font-bold text-[#FE6897]"
                : "font-medium"
            }`}
          >
            Handler Chats
          </h1>
        </div>

        {/* Sliding Underline */}
        <div
          className="absolute -bottom-2 z-10 h-1 bg-[#FE6897] rounded-full transition-all duration-300 ease-in-out"
          style={{
            width: `${sliderWidth}px`,
            transform: `translateX(${sliderPosition}px)`,
          }}
        ></div>

        {/* Bottom Line */}
        <div className="absolute -bottom-2 left-0 right-0 h-[4px] xl:h-[2px] bg-[#FEE5EE] rounded-full"></div>
      </div>

      {/* render */}
      <div className=" h-full overflow-hidden ">
        {isViewing === "messages" ? (
          <UserMessages />
        ) : (
          <ChatHandler bottomRefHeight={bottomRefHeight} />
        )}
      </div>
    </main>
  );
}

export default UserMessenger;
