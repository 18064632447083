import topLine from "./assets/top_line.png";
import userPlus from "./assets/user_plus.png";
import userCheck from "./assets/user_check.png";
import users from "./assets/users.png";
import heart from "./assets/heart.png";
import redBoard from "./assets/red_board.png";
import face from "./assets/face.png";
import verifyBackground from "./assets/verification.png";
import circle from "./assets/spin_circle.png";
import badge from "./assets/badge.png";
import verify_error from "./assets/verify_error.png";
import bdate from "./assets/bdate.png";
import vido from "./assets/vido.png";
import bdvid from "./assets/bdvid.png";
import blindicon from "./assets/blind.png";
import sub from "./assets/subscription.png";
import super_admin_login from "./assets/super_admin_login.png";
import therapist from "./assets/therapist.png";
import therapist1 from "./assets/therapist1.png";
import fadeBg from "./assets/fade_bg.png";
import therapyhero from "./assets/therapy_hero.png";
import thp1 from "./assets/thp1.png";
import thp2 from "./assets/thp2.png";
import thp3 from "./assets/thp3.png";
import thp4 from "./assets/thp4.png";
import vpro from "./assets/vpro.png";
import rej from "./assets/rej.png";

const ASSETS = {
  SuperAdminLogin: super_admin_login,
  Vpro: vpro,
  Rej: rej,
  blindicon,
  sub,
  TherapistIcon: therapist,
  Therapist1: therapist1,
  FadeBackground: fadeBg,
  TherapyHero: therapyhero,
  Thp1: thp1,
  Thp2: thp2,
  Thp3: thp3,
  Thp4: thp4,
  MainBg: "https://dearmacmedia.s3.amazonaws.com/assests/assets/hero5.png",
  Frame1: "https://dearmacmedia.s3.amazonaws.com/assests/assets/hero1.png",
  Frame2: "https://dearmacmedia.s3.amazonaws.com/assests/assets/hero2.png",
  Frame3: "https://dearmacmedia.s3.amazonaws.com/assests/assets/hero3.png",
  Frame4: "https://dearmacmedia.s3.amazonaws.com/assests/assets/hero4.png",
  Logo: "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg",
  nLogo: "https://dearmacmedia.s3.amazonaws.com/assests/assets/nlogo+1.png",
  LayerBlur:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/layer_blur.svg",
  Lightning:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/lightning_fill.svg",
  HeartCircle:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/heart-circle.svg",
  PremiumIcon:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/premium_icon.svg",
  SuccessStories:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/success_stories.svg",
  PaymentSuccess:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/money_payment.png",
  ProfileSaved:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/profile_saved.png",
  TopLine: topLine,
  UserViews:
    "https://dearmacmedia.s3.amazonaws.com/assests/assets/ABOUTIMG.png",
  JoinUser: "https://dearmacmedia.s3.amazonaws.com/assests/assets/whyN.png",
  UserPlus: userPlus,
  UserCheck: userCheck,
  Users: users,
  Heart: heart,
  RedBoard: redBoard,
  Face: face,
  Intouch: "https://dearmacmedia.s3.amazonaws.com/assests/assets/getin.png",
  join: "https://dearmacmedia.s3.amazonaws.com/assests/assets/join.png",
  VerifyBackground: verifyBackground,
  Circle: circle,
  Badge: badge,
  Verify_error: verify_error,
  Test1: "https://nirvanaemedia.s3.amazonaws.com/assets/IMG_4824.PNG",
  Test2: "https://nirvanaemedia.s3.amazonaws.com/assets/IMG_4821.PNG",
  Test3:
    "https://nirvanaemedia.s3.amazonaws.com/assets/WhatsApp+Image+2024-05-27+at+10.39.02.jpeg",
  Test4: "https://nirvanaemedia.s3.amazonaws.com/assets/IMG_4837.JPG",
  Test5: "https://nirvanaemedia.s3.amazonaws.com/assets/IMG_4838.JPG",
  Test6: "https://nirvanaemedia.s3.amazonaws.com/assets/IMG_4839.JPG",
  Test7: "https://nirvanaemedia.s3.amazonaws.com/assets/IMG_4840.JPG",
  Test8: "https://nirvanaemedia.s3.amazonaws.com/assets/IMG_4825.PNG",
  Avi: "https://res.cloudinary.com/horlarde/image/upload/v1721224325/avatar_gtbxbu.webp",
  Marvin:
    "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/marvin.svg",
  Bdate: bdate,
  Vido: vido,
  BdVid: bdvid,
  Logout:
    "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logout_sm.svg",
};

export default ASSETS;
