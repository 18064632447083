import { useMutation, useQuery } from "@apollo/client";
import { CheckCircleOutline, KeyboardBackspace } from "@mui/icons-material";
import { GET_SINGLE_THERAPIST } from "src/hooks/data/queries";
import { useState } from "react";
import DateCalendar from "./DateCalendar";
import moment from "moment";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { BeatLoader } from "react-spinners";
import { USER_BOOK_THERAPIST } from "src/hooks/data/mutations";
import { toast } from "react-toastify";
import { useUser } from "../auth/hooks/UserProvider";
import ASSETS from "src/asset";

export default function SelectedTherapist({
  selectedTherapist,
  handleBackClick,
}) {
  const { user } = useUser();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { data: therapistData, loading } = useQuery(GET_SINGLE_THERAPIST, {
    variables: {
      therapistId: Number(selectedTherapist?.id),
    },
    fetchPolicy: "network-only",
  });
  const mainTherapist = therapistData?.therapist;
  const currentDateavail = mainTherapist?.availability?.filter?.((i) =>
    i?.startTime?.includes?.(moment(selectedDate).format("YYYY-MM-DD"))
  );
  const [openBooking, setOpenBooking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTherapistTime, setSelectedTherapistTime] = useState(null);

  const [bookTherapist] = useMutation(USER_BOOK_THERAPIST);

  const handleBookingClick = () => {
    setIsLoading(true);
    bookTherapist({
      variables: {
        payload: {
          userId: Number(user?.id),
          therapistId: Number(selectedTherapist?.id),
          //@ts-ignore
          slotId: Number(selectedTherapistTime?.id),
          //@ts-ignore
          sessionDate: selectedTherapistTime?.startTime,
        },
      },
      onCompleted: (data) => {
        // closeModal();
        toast.success("Therapist booked successfully");
        setIsLoading(false);
        setOpenBooking(false);
        handleBackClick();
      },
      onError: (error) => {
        toast.error(error?.message);
        setIsLoading(false);
        console.log({ error });
      },
    });
  };

  const allAvailableDates = mainTherapist?.availability
    ? [
        ...new Set(
          mainTherapist?.availability?.map?.(
            (i) => i?.startTime?.split?.("T")?.[0]
          )
        ),
      ]
    : [];

  return (
    <>
      <ResponsiveOverlay
        isOpen={openBooking}
        setIsOpen={setOpenBooking}
        title={""}
        size="small"
      >
        <div className="w-full">
          <div className="w-full mx-auto gap-5 p-5 bg-white flex flex-col justify-center items-center">
            <div className="w-14 h-14 flex-shrink-0 rounded-full text-green-600 flex justify-center items-center bg-green-100">
              <CheckCircleOutline color="inherit" fontSize="large" />
            </div>
            <h2 className="text-2xl text-center canela text-[#344054] font-bold">
              Book Appointment
            </h2>
            <p className="text-sm text-center">
              You are about to make a therapeutic reservation for{" "}
              <span className="font-semibold">{`${moment(
                //@ts-ignore
                selectedTherapistTime?.startTime
              )?.format("h:mm a")}-${moment(
                //@ts-ignore
                selectedTherapistTime?.endTime
              )?.format("h:mm a")}`}</span>{" "}
              on{" "}
              <span className="font-semibold">
                {
                  //@ts-ignore
                  moment(selectedTherapistTime?.startTime)?.format?.(
                    "dddd Do MMMM YYYY"
                  )
                }
              </span>{" "}
              with{" "}
              <span className="font-semibold">{selectedTherapist?.name}</span>.
              Please confirm below.
            </p>
          </div>
          <div className="flex p-5 w-full justify-end gap-5 bg-gray-50 items-center">
            {!isLoading && (
              <button
                onClick={() => setOpenBooking(false)}
                className="px-6 py-2 rounded-lg text-center text-base font-semibold bg-white border border-gray-400 text-gray-700"
              >
                Cancel
              </button>
            )}
            <button
              disabled={isLoading}
              onClick={() => {
                handleBookingClick();
              }}
              className="px-6 py-2 rounded-lg text-center text-base font-semibold bg-blue-600 text-white"
            >
              {isLoading ? <BeatLoader size={10} color="#fff" /> : "Confirm"}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>
      <div className="space-y-5 w-full">
        <button
          onClick={() => handleBackClick()}
          className="flex outline-none font-medium hover:opacity-70 border-none gap-2 items-center text-lg"
        >
          <KeyboardBackspace fontSize="inherit" />
          Back
        </button>
        <div className="flex gap-5">
          <div className="flex-grow py-2 bg-white divide-y divide-gray-300 rounded-lg space-y-5">
            <div className="p-4 space-y-3">
              <div className="flex gap-5 items-start">
                <div className="w-10 sm:w-20 p-2 h-10 sm:h-20 flex-shrink-0 bg-gradient-to-r from-[#FE6D68] to-[#FEC76A] rounded-full">
                  <img
                    src={mainTherapist?.imageUrl ?? ASSETS?.Avi}
                    alt=""
                    className="h-full w-full rounded-lg object-contain"
                  />
                </div>
                <div>
                  <h2 className="font-semibold text-base sm:text-lg">
                    {mainTherapist?.name}
                  </h2>
                  <p className="text-xs sm:text-sm">{mainTherapist?.tagline}</p>
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                {mainTherapist?.specialties?.length > 0 ? (
                  mainTherapist?.specialties?.map((tag, idx) => (
                    <p
                      key={idx}
                      className={` rounded border border-gray-600 bg-gray-100 text-gray-600 px-2 py-0.5 whitespace-nowrap text-[9px] sm:text-xs font-medium w-max  `}
                    >
                      {tag}
                    </p>
                  ))
                ) : (
                  <p className="text-xs font-medium italic">
                    No tags available
                  </p>
                )}
              </div>
            </div>
            <div className="w-full lg:hidden p-4 space-y-5 ">
              <h2 className="text-sm text-center font-semibold">
                Select preferred date
              </h2>
              <DateCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setSelectedTherapistTime={setSelectedTherapistTime}
                allAvailableDates={allAvailableDates}
              />
              {currentDateavail?.length > 0 ? (
                <div className="space-y-4">
                  <p className="text-xs text-center">Select avaialable time</p>
                  <div className="flex items-center flex-wrap border rounded-sm p-2 gap-3">
                    {currentDateavail?.map?.((day, id) => (
                      <p
                        key={id}
                        onClick={() => {
                          if (day?.isBooked) {
                            return toast.info(
                              "This time is already taken! Kindly choose another time or day."
                            );
                          }
                          setSelectedTherapistTime(day);
                        }}
                        className={` ${
                          //@ts-ignore
                          selectedTherapistTime?.id === day?.id
                            ? "border-blue-600 bg-blue-600 text-white"
                            : day?.isBooked
                            ? "bg-gray-400 border-gray-600"
                            : "hover:bg-gray-100 border-gray-600 bg-white text-gray-600"
                        } rounded cursor-pointer border  px-2.5 py-1 whitespace-nowrap text-xs font-medium w-max  `}
                      >
                        {`${moment(day?.startTime)?.format("h:mm a")}-${moment(
                          day?.endTime
                        )?.format("h:mm a")}`}
                      </p>
                    ))}
                  </div>
                  <button
                    disabled={!selectedTherapistTime}
                    onClick={() => setOpenBooking(true)}
                    className="px-4 hover:opacity-70 rounded-lg py-2.5 w-full text-sm font-medium text-center disabled:bg-gray-400 bg-blue-600 text-white"
                  >
                    Book Appointment
                  </button>
                </div>
              ) : (
                <div className="bg-gray-100 border border-gray-600 text-center text-sm p-2">
                  Not available on this day
                </div>
              )}
            </div>
            <div className="p-4 space-y-3">
              <h2 className="text-sm sm:text-base font-semibold">Bio</h2>
              <p className="text-xs sm:text-sm">
                {mainTherapist?.personaSummary}
              </p>
            </div>
            <div className="p-4 space-y-3">
              <h2 className="text-sm sm:text-base font-semibold">
                Professional Training and Certifications
              </h2>
              <div className="pl-5">
                <ul className=" list-disc">
                  {mainTherapist?.educationAndExpertise?.map?.((item, id) => (
                    <li className="text-xs sm:text-sm " key={id}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="p-4 space-y-3">
              <h2 className="text-sm sm:text-base font-semibold">
                Your Benefits - (What you get)
              </h2>
              <div className="pl-5">
                <ul className=" list-disc">
                  {mainTherapist?.whatToExpect?.map?.((item, id) => (
                    <li className="text-xs sm:text-sm " key={id}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="p-4 space-y-3">
              <h2 className="text-sm sm:text-base font-semibold">Philosophy</h2>
              <p className="text-xs sm:text-sm">{mainTherapist?.philosophy}</p>
            </div>
          </div>
          <div className="hidden lg:inline-block w-4/12 flex-shrink-0 space-y-5  rounded-lg divide-gray-300">
            <div className="w-full p-4 bg-white rounded-t-md ">
              <h2 className="text-sm font-semibold">Select preferred date</h2>
            </div>
            <div className="w-full p-4 space-y-5 bg-white rounded-b-md ">
              <DateCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setSelectedTherapistTime={setSelectedTherapistTime}
                allAvailableDates={allAvailableDates}
              />
              {currentDateavail?.length > 0 ? (
                <div className="space-y-4">
                  <p className="text-xs text-center">Select avaialable time</p>
                  <div className="flex items-center flex-wrap border rounded-sm p-2 gap-3">
                    {currentDateavail?.map?.((day, id) => (
                      <p
                        key={id}
                        onClick={() => {
                          if (day?.isBooked) {
                            return toast.info(
                              "This time is already taken! Kindly choose another time or day."
                            );
                          }
                          setSelectedTherapistTime(day);
                        }}
                        className={` ${
                          //@ts-ignore
                          selectedTherapistTime?.id === day?.id
                            ? "border-blue-600 bg-blue-600 text-white"
                            : day?.isBooked
                            ? "bg-gray-400 border-gray-600"
                            : "hover:bg-gray-100 border-gray-600 bg-white text-gray-600"
                        } rounded cursor-pointer border  px-2.5 py-1 whitespace-nowrap text-xs font-medium w-max  `}
                      >
                        {`${moment(day?.startTime)?.format("h:mm a")}-${moment(
                          day?.endTime
                        )?.format("h:mm a")}`}
                      </p>
                    ))}
                  </div>
                  <button
                    disabled={!selectedTherapistTime}
                    onClick={() => setOpenBooking(true)}
                    className="px-4 hover:opacity-70 rounded-lg py-2.5 w-full text-sm font-medium text-center disabled:bg-gray-400 bg-blue-600 text-white"
                  >
                    Book Appointment
                  </button>
                </div>
              ) : (
                <div className="bg-gray-100 border border-gray-600 text-center text-sm p-2">
                  Not available on this day
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
