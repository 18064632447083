import React, { useEffect, useRef, useState } from "react";
import UserTherapy from "./UserTherapy";
import UserBlindDate from "./UserBlindDate";
import { useLocation } from "react-router-dom";

function UserMeetings() {
  const location = useLocation();
  // @ts-ignore
  const pageReference = location?.state?.pageRef || "";

  const [isViewing, setIsViewing] = useState(
    pageReference ? "blindDate" : "therapy"
  );
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(0);

  const therapyRef = useRef<HTMLHeadingElement>(null);
  const blinDateRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const updateSlider = () => {
      const activeRef =
        isViewing === "therapy" ? therapyRef.current : blinDateRef.current;
      if (activeRef) {
        setSliderWidth(activeRef.offsetWidth);
        setSliderPosition(activeRef.offsetLeft);
      }
    };
    updateSlider();
  }, [isViewing]);
  return (
    <div className="space-y-10">
      {/* User Select */}
      <div className="relative">
        {/* Text Options */}
        <div className="flex items-center  text-base pb-1 md:text-base xl:text-lg w-full ">
          <h1
            ref={therapyRef}
            onClick={() => setIsViewing("therapy")}
            className={`cursor-pointer w-1/2 text-center ${
              isViewing === "therapy"
                ? "font-bold text-[#FE6897]"
                : "font-medium"
            }`}
          >
            Nirvanae Wellness
          </h1>
          <h1
            ref={blinDateRef}
            onClick={() => setIsViewing("blindDate")}
            className={`cursor-pointer  w-1/2 text-center  ${
              isViewing === "blindDate"
                ? "font-bold text-[#FE6897]"
                : "font-medium"
            }`}
          >
            Blind Dates
          </h1>
        </div>

        {/* Sliding Underline */}
        <div
          className="absolute -bottom-2 z-10 h-1 bg-[#FE6897] rounded-full transition-all duration-300 ease-in-out"
          style={{
            width: `${sliderWidth}px`,
            transform: `translateX(${sliderPosition}px)`,
          }}
        ></div>

        {/* Bottom Line */}
        <div className="absolute -bottom-2 left-0 right-0 h-[4px] xl:h-[2px] bg-[#FEE5EE] rounded-full"></div>
      </div>
      {/* render */}
      {isViewing === "therapy" ? <UserTherapy /> : <UserBlindDate />}
    </div>
  );
}

export default UserMeetings;
